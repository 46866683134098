import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from 'assets/styles/jobDetailsBox';
import ApplyIcon from 'assets/icons/ApplyIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import ShareIcon from 'assets/icons/ShareIcon';
import Cookies from 'js-cookie';

const JobDetailsBox = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [applied, setApplied] = useState(false);

    const isJobApplied = (job_id) => {
    let appliedJobs = Cookies.get('applied_jobs');
    if (!appliedJobs) {
      return false;
    }
    appliedJobs = JSON.parse(appliedJobs);
    return appliedJobs.includes(job_id);
    };
  useEffect(() => {
    setApplied(isJobApplied(data.job_id));
  
  })

  const handleApply = async () => {
    navigate(`/job/${data.job_id}`);
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: data.title,
          url: `/?id=${data.job_id}`,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      console.warn('Web Share API not supported in this browser.');
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.typography}>
          {data.job_title}
        </Typography>
        <Box sx={styles.link} onClick={handleShare}>
        <ShareIcon />
        </Box>
      </Box>
      <Box sx={styles.row}>
        <Box sx={styles.jobTypes}>
          <Box sx={styles.jobType}>
            {data.job_status || 'Full-time'}
          </Box>
          <Box sx={styles.textContainer}>
            <Typography sx={styles.locationType}>
              {data.location}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.applyButton} onClick={() => !applied ? handleApply() : null}>
            {applied ? " Applied " : "Apply"}
            {!applied && <ApplyIcon /> }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default JobDetailsBox;
