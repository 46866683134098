import React from 'react'

const DescendingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 13.3333H10.6667M2 10.6666H8M2 7.99992H6M8.66667 7.99992L11.3333 10.6666M11.3333 10.6666L14 7.99992M11.3333 10.6666V2.66658" stroke="#111827" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default DescendingIcon