import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import styled from 'styled-components';

const styles = {
  selectContainer: {
    display: "flex",
    justifyContent: "right"
  },
  selectInput: {
    width: '100%',
    maxWidth: 137,
    height: 45,
    borderRadius: '6px',
    background: 'rgba(255, 255, 255, 0.00)',
    color: '#646464',
    fontFamily: 'Poppins',
    outline: "none",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    textAlign: "right"
  },
  MenuItem: {
    background: "#FFF",
    outline: "none",
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: 4
  },
}

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));

const CustomBarChart = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/total-applications`);
        const { available_years, total_applications } = response.data;

        setAvailableYears(available_years);
        if (available_years.length > 0) {
          setSelectedYear(available_years[0]);
        }

        const filteredData = total_applications
          .filter(item => item.total_applications > 0)
          .map(item => ({
            name: item.department_name,
            value: item.total_applications,
          }));

        const maxVal = Math.max(...filteredData.map(item => item.value));
        setMaxValue(maxVal);
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching total applications data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataByYear = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/total-applications`);
        const { total_applications } = response.data;

        const filteredData = total_applications
          .filter(item => item.year === selectedYear && item.total_applications > 0)
          .map(item => ({
            name: item.department_name,
            value: item.total_applications,
          }));

        const maxVal = Math.max(...filteredData.map(item => item.value));
        setMaxValue(maxVal);
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data by year:', error);
      }
    };

    if (selectedYear) {
      fetchDataByYear();
    }
  }, [selectedYear]);

  const handleSelect = (event) => {
    setSelectedYear(event.target.value);
  }

  return (
    <Box>
      <Box style={styles.selectContainer}>
        <CustomSelect
          name="year"
          value={selectedYear}
          style={styles.selectInput}
          onChange={handleSelect}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
              },
            },
          }}
        >
          {availableYears.map(year => (
            <MenuItem key={year} style={styles.MenuItem} value={year}>
              {year} - {year+1}
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
          barCategoryGap="80px"
          barSize={24}
        >
          <CartesianGrid strokeDasharray="none" />
          <XAxis 
            dataKey="name" 
            tick={{ fontSize: 12, fontFamily: "Poppins", width: "50px", color: "#646464" }} 
          />
          <YAxis domain={[0, maxValue * 2]} tick={{ fontSize: 12, fontFamily: "Poppins", color: "#646464" }}  />
          <Tooltip />
          <Bar dataKey="value" fill={theme.purple} radius={[0, 0, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomBarChart;
