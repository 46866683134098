import React from 'react'

const EyeIcon = ({color = "black"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M3.64219 14.8013C3.10885 14.1063 3.10885 13.1397 3.64219 12.4447C8.67814 5.88186 18.5687 5.88186 23.6047 12.4447C24.138 13.1397 24.138 14.1063 23.6047 14.8013C18.5687 21.3642 8.67814 21.3642 3.64219 14.8013Z" stroke={color}/>
  <g filter="url(#filter0_d_611_1080)">
    <path d="M13.5 16.3125C15.0533 16.3125 16.3125 15.0533 16.3125 13.5C16.3125 11.9467 15.0533 10.6875 13.5 10.6875C11.9467 10.6875 10.6875 11.9467 10.6875 13.5C10.6875 15.0533 11.9467 16.3125 13.5 16.3125Z" stroke={color} shape-rendering="crispEdges"/>
  </g>
  <defs>
    <filter id="filter0_d_611_1080" x="6.1875" y="10.1875" width="14.625" height="14.625" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_611_1080"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_611_1080" result="shape"/>
    </filter>
  </defs>
</svg>
  )
}

export default EyeIcon