import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Input, CircularProgress } from '@mui/material';
import ApplicationFormHeader from 'components/ApplicationFormHeader';
import step_0 from 'assets/images/step-0.svg';
import step_1 from 'assets/images/step-1.svg';
import step_2 from 'assets/images/step-2.svg';
import theme from 'theme';
import DocIcons from 'assets/images/upload_docs.png';
import styles from 'assets/styles/applicationForm';
import cross_ico from 'assets/images/cross_btn.png';
import JobSubmitModal from 'components/JobSubmitModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import Cookies from 'js-cookie';
import countries from 'assets/constants/countries';
import LocationModal from 'components/LocationModal';

const ApplicationForm = () => {
  const token = localStorage.getItem('token');
  const [profile, setProfile] = useState()
  const { jobProfiles } = useAppContext();
  const [jobCountry, setJobCountry] = useState(null);
  const [userCountry, setUserCountry] = useState(null);
  useEffect(() => {
    const data = jobProfiles.find(profile => profile.job_id === id);
    const countryCode = data?.location.split(",").pop().trim();
    setJobCountry(countryCode)
    setProfile(data)
  }, [jobProfiles])
  const { id } = useParams();
  const { updateJobProfile } = useAppContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    job_id: id,
    name: '',
    totalExperience: '',
    noticePeriod: '',
    portfolioLink: '',
    designation: '',
    contactNumber: '',
    email: '',
    coverLetter: '',
    salaryExpectation: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    contactNumber: '',
    email: '',
    portfolioLink: '',
    designation: '',
    salaryExpectation: '',
    coverLetter: '',
    noticePeriod: '',
    totalExperience: ''
  });

  const checkFormCompletion = () => {
    const isComplete = Object.values(formValues).every((value) => value.trim() !== '') && selectedFile !== null;
    setFormCompleted(isComplete);
  };

  useEffect(() => {
    checkFormCompletion();
  }, [formValues, selectedFile]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    document.getElementById('fileInput').click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contactNumber' || name === 'salaryExpectation' || name === 'noticePeriod') {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = '';
    switch (fieldName) {
      case 'name':
        error = value.trim() ? '' : 'Name is required';
        break;
      case 'contactNumber':
        error = value.trim() ? '' : 'Contact number is required';
        break;
      case 'email':
        error = value.trim() ? (isValidEmail(value) ? '' : 'Invalid email address') : 'Email is required';
        break;
      case 'portfolioLink':
        error = value.trim() ? (isValidLink(value) ? '' : 'Invalid link format') : 'Portfolio Link is required';
        break;
      case 'designation':
        error = value.trim() ? '' : 'Designation is required';
        break;
      case 'salaryExpectation':
        error = value.trim() ? '' : 'Salary Expectation is required';
        break;
      case 'coverLetter':
        error = value.trim() ? '' : 'Cover Letter is required';
        break;
      case 'noticePeriod':
        error = value.trim() ? '' : 'Notice Period is required';
        break;
      case 'totalExperience':
        error = value.trim() ? '' : 'Total Experience is required';
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidLink = (link) => {
    const trimmedLink = link.trim().toLowerCase();
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}$/;
    return urlRegex.test(trimmedLink);
  };

useEffect(() => {
  fetch('https://ipwhois.app/json/')
    .then(response => response.json())
    .then(data => {
      console.log(`City: ${data.city}`);
      console.log(`Region: ${data.region}`);
      console.log(`Country: ${data.country}`);
      setUserCountry(data.country);
    })
    .catch(error => console.error('Error fetching location:', error));
}, []);
  
  const getCountryNameByCode = (code) => {
  const country = countries.find(country => country.code === code);
  return country ? country.name : null;
  };
  
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userCountry !== getCountryNameByCode(jobCountry)) {
      setLocationModal(true)
      toast.error('Your location does not match the job’s requirement');
    }
    else {
    setIsSubmitting(true);
    event.preventDefault();
    Object.keys(formValues).forEach((fieldName) => {
      validateField(fieldName, formValues[fieldName]);
    });

    const hasErrors = Object.values(errors).some((error) => error !== '');
    if (formCompleted && !hasErrors) {
      const formData = new FormData();
      const totalExperience = Number(formValues.totalExperience) + Number((formValues.totalExperienceInMonths / 12).toFixed(2))
      formData.append('job_id', formValues.job_id);
      formData.append('job_title', profile.job_title);
      formData.append('name', formValues.name);
      formData.append('experience', totalExperience);
      formData.append('notice_period', formValues.noticePeriod);
      formData.append('portfolio_link', formValues.portfolioLink);
      if (selectedFile) {
        formData.append('resume', selectedFile);
      }
      formData.append('current_designation', formValues.designation);
      formData.append('contact_number', formValues.contactNumber);
      formData.append('email', formValues.email);
      formData.append('expected_salary', formValues.salaryExpectation);
      formData.append('summary', formValues.coverLetter);
      formData.append('status', 'New');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/dashboard/applications`,
          formData,
          {
            headers: {
              Origin: '',
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        setSubmitModal(true);
        console.log('Form submitted:', response.data);
        let appliedJobs = Cookies.get('applied_jobs');
        if (!appliedJobs) {
          appliedJobs = [];
        } else {
          appliedJobs = JSON.parse(appliedJobs);
        }
        if (!appliedJobs.includes(formValues.job_id)) {
          appliedJobs.push(formValues.job_id);
        }
        Cookies.set('applied_jobs', JSON.stringify(appliedJobs), { expires: 30 }); // Cookie expires in 30 days
        resetForm();
      } catch (error) {
        toast.error(error || "Error submitting form");
        console.error('Error submitting form:', error);
      }
    }
    setIsSubmitting(false);
  };
}

  const resetForm = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    setFormValues({
      job_id: id,
      name: '',
      contactNumber: '',
      email: '',
      portfolioLink: '',
      designation: '',
      salaryExpectation: '',
      coverLetter: '',
      noticePeriod: '',
      totalExperience: '',
      totalExperienceInMonths: ''
    });
    setFormCompleted(false);
    setErrors({
      name: '',
      contactNumber: '',
      email: '',
      portfolioLink: '',
      designation: '',
      salaryExpectation: '',
      coverLetter: '',
      noticePeriod: '',
      totalExperience: '',
    });
  };

  const handleStepper = () => {
    if (selectedFile && formCompleted) {
      return step_2;
    } else if (selectedFile && !formCompleted) {
      return step_1;
    } else {
      return step_0;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box style={styles.container}>
      <ToastContainer/>
      <JobSubmitModal visible={submitModal} />
      <LocationModal visible={locationModal} />
      <ApplicationFormHeader />
      <form onSubmit={handleSubmit}>
        <Box style={styles.sectionContainer}>
          <Box>
            <img src={handleStepper()} alt="stepper" />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography style={{ ...styles.title, color: selectedFile ? '#000' : theme.purple }}>Resume</Typography>
            <Box style={{display: "inline-block"}} {...getRootProps()}>
              <Input
              id="fileInput"
              type="file"
              style={styles.fileInput}
              onChange={handleFileChange}
              accept=".pdf, .doc, .docx"
            />
            {selectedFile ? (
              <Button
                variant="contained"
                color="primary"
                style={{ ...styles.button, ...styles.fileButtonContainer }}
              >
                {selectedFile.name}
                <button
                  style={styles.crossBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFile(null);
                  }}
                >
                  <img style={styles.crossIcon} src={cross_ico} alt="cross icon" />
                </button>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ ...styles.button, ...styles.uploadButton }}
                onClick={handleButtonClick}
              >
                <img src={DocIcons} alt="doc icons" />
                    <Typography style={styles.uploadText}>
                  {isDragActive ? 'Drop the files here ...' : <>Drag and drop files here, or <Typography style={styles.browseText}>browse</Typography> your computer</>}
                </Typography>
              </Button>
              )}
            </Box>
            <Typography style={{ ...styles.title, margin: '45px 0 14px', color: !selectedFile && !formCompleted ? '#000' : theme.purple }}>
              Contact Information
            </Typography>
            {[
              ['name', 'contactNumber'],
              ['email', 'portfolioLink'],
              ['designation', 'salaryExpectation'],
            ].map((pair, index) => (
              <Box key={index} style={styles.fieldRow}>
                {pair.map((field, subIndex) => (
                  <Box key={subIndex} style={styles.fieldBox}>
                    <Typography style={styles.label} htmlFor={field}>
                      {field === 'name'
                        ? 'Name'
                        : field === 'salaryExpectation'
                        ? 'Salary Expectation'
                        : field === 'designation'
                        ? 'Current Designation'
                        : field === 'email'
                        ? 'Email'
                        : field === 'contactNumber'
                        ? 'Contact Number'
                        : field === 'portfolioLink'
                        ? 'Link to Portfolio/LinkedIn'
                        : field.split(/(?=[A-Z])/).join(' ')}{' '}
                      *
                    </Typography>
                        <input
                          id={field}
                          name={field}
                          style={styles.inputField}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            if (name === 'contactNumber') {
                              const cleanedValue = value.replace(/[^\d]/g, '').slice(0, 12);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                [name]: cleanedValue,
                              }));
                            } else if (name === 'salaryExpectation' || name === 'noticePeriod') {
                            const cleanedValue = value.replace(/[^\d]/g, '');
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: cleanedValue,
                            }));
                          } else {
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: value,
                            }));
                          }
                        }}
                        placeholder={field === 'salaryExpectation' && "X LPA"}
                        value={formValues[field]}
                        required
                        onBlur={() => validateField(field, formValues[field])}
                      />
                    {errors[field] && (
                      <Typography variant="caption" style={{ color: 'red' }}>
                        {errors[field]}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ))}
            <Box style={styles.fieldRow}>
              <Box style={styles.fieldBox}>
                <Typography style={styles.label} htmlFor="coverLetter">
                  Summary/ Cover Letter *
                </Typography>
                <textarea
                  id="coverLetter"
                  name="coverLetter"
                  style={styles.textArea}
                  onChange={handleInputChange}
                  value={formValues.coverLetter}
                  rows={4}
                  onBlur={() => validateField('coverLetter', formValues.coverLetter)}
                />
                {errors['coverLetter'] && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {errors['coverLetter']}
                  </Typography>
                )}
              </Box>
              <Box style={styles.fieldBox}>
              <Typography style={styles.label} htmlFor="totalExperience">
                  Total Experience *
                </Typography>
                <Box style={styles.experienceFields}>
                  <input
                    id="totalExperience"
                    name="totalExperience"
                    type="text" 
                    placeholder='in years'
                    style={styles.inputField}
                    onChange={handleInputChange}
                    value={formValues.totalExperience}
                    required
                    onBlur={() => validateField('totalExperience', formValues.totalExperience)}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\d]/g, '');
                    }}
                  />
                  <input
                    id="totalExperienceInMonths"
                    name="totalExperienceInMonths"
                    type="text" 
                    placeholder='in Months'
                    style={styles.inputField}
                    onChange={handleInputChange}
                    value={formValues.totalExperienceInMonths}
                    required
                    onInput={(e) => {
                      if (e.target.value > 11) {
                        e.target.value = 11
                      }
                      e.target.value = e.target.value.replace(/[^\d]/g, '');
                    }}
                    maxLength={2}
                  />
                </Box>
                {errors['totalExperience'] && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {errors['totalExperience']}
                  </Typography>
                )}
              </Box>
            </Box>
              <Box style={styles.fieldBox}>
              <Typography style={styles.label} htmlFor="noticePeriod">
                  Notice Period *
                </Typography>
                <input
                  id="noticePeriod"
                  name="noticePeriod"
                  type="number"
                  placeholder='in days'
                  style={{ ...styles.inputField, ...styles.NoticeField }}
                  onChange={handleInputChange}
                  value={formValues.noticePeriod}
                  required
                  onBlur={() => validateField('noticePeriod', formValues.noticePeriod)}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, '');
                  }}
                />
                {errors['noticePeriod'] && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {errors['noticePeriod']}
                  </Typography>
                )}
              </Box>
            <Box style={styles.actionButtons}>
              <Button
                variant="outlined"
                style={styles.cancelButton}
                onClick={resetForm}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{
                  ...styles.submitButton,
                  ...(formCompleted ? {} : styles.disabledButton),
                }}
                disabled={!formCompleted}
              >
                {isSubmitting ? <><CircularProgress size={24} /> Submitting Your Application</> : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ApplicationForm;
