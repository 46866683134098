import theme from "theme";
 
const styles = {
    container: {
      padding: '15px 45px 16px 45px',
      borderBottom: '1px solid #ECEDF2',
      background: '#FFF',
      boxShadow: '0px 6px 15px 0px rgba(64, 79, 104, 0.05)',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logoBox: {
      height: '50px',
      display: 'flex',
      alignItems: 'center'
    },
    linkBox: {
      display: 'flex',
      gap: '15px',
    },
    link: {
      borderRadius: '8px',
      border: '1px solid rgba(25, 103, 210, 0.01)',
      background: 'rgba(25, 103, 210, 0.07)',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '28px',
      textAlign: 'center',
      textDecoration: 'none',
      color: '#000',
      display: 'flex',
      padding: "13px 31px",
      alignItems: 'center',
      textTransform: "capitalize"
    },
    backButton:{
      display: "flex",
      gap: "5px",
      alignItems: "center"
    },
    userProfileBox: {
      display: "flex",
      gap: 16
    },
    userAvatar: {
      width: "32px",
      height: "32px",
    },
    userName:{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "18px",
      textAlign: "left",
      color: "#1A1A1A",
    },
    logoutBox: {
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "16px",
      color: "#1A1A1A",
    },
    logoutBtn: {
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "16px",
      color: theme.purple,
      padding: 0,
      margin: 0,
      minWidth: 0,
    }
  };

  export default styles