import React from 'react'

const FilesIcon = ({active=false}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M16.4062 1.5625H5.46875C4.84715 1.5625 4.25101 1.80943 3.81147 2.24897C3.37193 2.68851 3.125 3.28465 3.125 3.90625V17.9688C3.125 18.5904 3.37193 19.1865 3.81147 19.626C4.25101 20.0656 4.84715 20.3125 5.46875 20.3125H6.25V21.0938C6.25 21.7154 6.49693 22.3115 6.93647 22.751C7.37601 23.1906 7.97215 23.4375 8.59375 23.4375H16.4062C16.5091 23.4381 16.611 23.4184 16.7062 23.3795C16.8014 23.3406 16.8879 23.2833 16.9609 23.2109L21.6484 18.5234C21.7208 18.4504 21.7781 18.3639 21.817 18.2687C21.8559 18.1735 21.8756 18.0716 21.875 17.9688V7.03125C21.875 6.40965 21.6281 5.81351 21.1885 5.37397C20.749 4.93443 20.1529 4.6875 19.5312 4.6875H18.75V3.90625C18.75 3.28465 18.5031 2.68851 18.0635 2.24897C17.624 1.80943 17.0279 1.5625 16.4062 1.5625ZM8.59375 4.6875C7.97215 4.6875 7.37601 4.93443 6.93647 5.37397C6.49693 5.81351 6.25 6.40965 6.25 7.03125V18.75H5.46875C5.26155 18.75 5.06284 18.6677 4.91632 18.5212C4.76981 18.3747 4.6875 18.176 4.6875 17.9688V3.90625C4.6875 3.69905 4.76981 3.50034 4.91632 3.35382C5.06284 3.20731 5.26155 3.125 5.46875 3.125H16.4062C16.6135 3.125 16.8122 3.20731 16.9587 3.35382C17.1052 3.50034 17.1875 3.69905 17.1875 3.90625V4.6875H8.59375ZM19.2109 18.75L17.1875 20.7812V19.5312C17.1875 19.324 17.2698 19.1253 17.4163 18.9788C17.5628 18.8323 17.7615 18.75 17.9688 18.75H19.2109Z" fill={active? "#FDE8FF" : "#646464"}/>
    </svg>
  )
}

export default FilesIcon