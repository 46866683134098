import theme from "theme";

const styles = {
    dashboard: {
      display: 'flex',
    },
    container: {
      flexGrow: 1,
      padding: '20px',
      paddingBottom: "100px"
    },
    heading: {
      marginBottom: '20px',
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    formContainer: {
      boxShadow: "none"
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      marginBottom: '20px',
    },
    label: {
      minWidth: '103px',
      maxWidth: '103px',
      whiteSpace: 'wrap',
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '28px',
    },
    selectInput: {
      width: '100%',
      maxWidth: 279,
      height: 45,
      borderRadius: '6px',
      border: '1px solid #6D0185',
      background: 'rgba(255, 255, 255, 0.00)',
      color: '#000',
      fontFamily: 'Poppins',
      outline: "none",
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    input: {
      width: '100%',
      padding: '10px',
      borderRadius: '6px',
      border: '1px solid #6D0185',
      background: 'rgba(255, 255, 255, 0.00)',
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      outline: "none",
      fontWeight: '400',
      lineHeight: '28px',
    },
    textarea: {
      width: '100%',
      maxWidth: 279,
      boxSizing: "border-box",
      padding: '10px',
      borderRadius: '6px',
      border: '1px solid #6D0185',
      background: 'rgba(255, 255, 255, 0.00)',
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      outline: "none",
      lineHeight: '28px',
      resize: 'vertical',
    },
    textEditorArea:{
      width: '100%',
      maxWidth: 320,
      boxSizing: "border-box",
      borderRadius: '6px',
      border: '1px solid #6D0185',
      background: 'rgba(255, 255, 255, 0.00)',
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '28px',
      resize: 'vertical',
     },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'left',
      marginTop: '20px',
    },
    submitButton: {
      borderRadius: '6px',
      background: '#6D0185',
      color: '#FFF',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      padding: "7px 44px",
    },
    onSiteBtn: {
      borderTopLeftRadius: "50px",
      borderBottomLeftRadius: "50px",
    },
    remoteBtn: {
      borderTopRightRadius: "50px",
      borderBottomRightRadius: "50px",
    },
    jobTypeButton: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      textTransform: "capitalize",
      minWidth: 0,
      padding: "8px 16px",
      margin: 0,
    },
    MenuItem: {
      // borderRadius: "6px",
      // borderBottom: `1px solid ${theme.purple}`,
      background: "#FFF",
      outline: "none",
      paddingTop: 12,
      paddingBottom: 12,
      marginTop: 4
    },
    addModal: {
      outline: "none",
      borderRadius: "6px",
      background: "#FFF",
      padding: 15,
      width: "292px",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"
    },
    applyButton: {
      float: "right",
      borderRadius: '12px',
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      color: '#FFF',
      fontFamily: 'Poppins',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '23.4px',
      textTransform: 'capitalize',
      padding: 0,
    },
    modalHead: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    modalTitle:{
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: theme.purple
    }
  };

  export default styles