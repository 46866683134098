import theme from 'theme';
const styles = {
    container: {
      padding: '0 0 63px',
      marginBottom: '53px',
    },
    sectionContainer: {
      padding: '0 179px 0 79px',
      marginTop: '50px',
      display: 'flex',
      gap: '50px',
      alignItems: 'flex-start',
    },
    title: {
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '23.4px',
      marginTop: '5px'
    },
    label: {
      color: '#565656',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '23.4px',
      marginBottom: '10px',
    },
    errorMessage: {
      color: 'red',
      fontSize: '14px',
      marginTop: '5px',
    },
    fileInput: {
      display: 'none',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '37px',
      borderRadius: '25px',
      padding: '9px 28px 16px',
      background: 'transparent',
      color: '#565656',
      minHeight: "106px",
      boxSizing: "border-box",
      width: "100%",
      maxWidth: "577px",
      maxHeight: "106px",
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '23.4px',
      border: `1px dotted ${theme.purple}`,
    },
    inputField: {
      borderRadius: '8px',
      border: `1px solid ${theme.purple}`,
      outline: "none",
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23.4px',
      padding: '8px 12px',
      width: '100%',
      boxSizing: 'border-box',
      height: '49px',
      '&:focus': {
      outline: 'none', 
    },
    },
    textArea: {
      borderRadius: '8px',
      border: `1px solid ${theme.purple}`,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23.4px',
      padding: '8px 12px',
      width: '100%',
      height: '50px',
      boxSizing: 'border-box',
    },
    fieldRow: {
      display: 'flex',
      gap: '50px',
      marginTop: '30px',
      marginBottom: '16px',
    },
    fieldBox: {
      flex: 1,
    },
    NoticeField: {
      width: '171px',
      boxSizing: 'border-box',
    },
    actionButtons: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '20px',
    },
    cancelButton: {
      borderRadius: '12px',
      border: `1px solid ${theme.backGreyFilter}`,
      background: theme.backGreyFilter,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      padding: '8px 20px',
      color: theme.purple,
      cursor: 'pointer',
      textTransform: "Capitalize"
    },
    submitButton: {
      borderRadius: '12px',
      cursor: 'pointer',
      border: `1px solid ${theme.purple}`,
      background: `${theme.purple}`,
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      padding: '8px 20px',
      color: '#fff',
      textTransform: "Capitalize",
    },
    disabledButton: {
        opacity: 0.6,
        cursor: 'not-allowed',
      },
    uploadButton: {
      display: "flex",
      flexDirection: 'column',
      gap: "5px",
      color: "#565656",
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "capitalize",
      fontStyle: "italic"
    },
    uploadText: {
      fontFamily: 'Poppins',
      fontSize: "16px",
      fontWeight: "600",
      color: "#565656",
      display: "flex",
      gap: "5px",
    },
    browseText: {
      fontFamily: 'Poppins',
      fontSize: "16px",
      fontWeight: "600",
      color: theme.purple,
    },
    fileButtonContainer : {
      display: "flex",
      flexDirection :"row",
      justifyContent: "space-between",
      paddingLeft: "89px",
      paddingTop: "20px",
      alignItems: "center",
      paddingRight: "50px",
    },
    crossBtn: {
      background: "none",
      border: "none",
      cursor: "pointer"
    },
    crossIcon :{ 
      width: "41px",
      height: "41px"
  },
  experienceFields: {
    display: "flex",
    gap: 10
    }
  };

  export default styles;