import React from 'react'

const PrintIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.7477 7.5H5.25234C3.60113 7.5 2.25 8.85014 2.25 10.5V17H5.99527V21H18.0047V17H21.75V10.5C21.75 8.85014 20.3988 7.5 18.7477 7.5ZM16.5 19.5H7.5V13.5H16.5V19.5ZM18.0047 3H5.99527V6.75H18.0047V3H18.0047Z" fill="white"/>
    </svg>
  )
}

export default PrintIcon