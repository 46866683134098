import { Box } from '@mui/material'
import DashboardSidebar from 'components/DashboardSidebar'
import React from 'react'

const AccessManagement = () => {
  return (
    <Box>
    <DashboardSidebar page="Access Management"/>
  </Box>
  )
}

export default AccessManagement