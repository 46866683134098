import React from 'react'

const AccessManagementIcon = ({active=false}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <g clipPath="url(#clip0_632_103)">
    <path d="M12.1325 10.4167C11.0203 10.4167 10.1104 11.3542 10.1104 12.5C10.1104 13.6458 11.0203 14.5833 12.1325 14.5833C13.2446 14.5833 14.1545 13.6458 14.1545 12.5C14.1545 11.3542 13.2446 10.4167 12.1325 10.4167ZM19.2097 3.125H5.05526C3.93302 3.125 3.0332 4.0625 3.0332 5.20833V19.7917C3.0332 20.9375 3.93302 21.875 5.05526 21.875H19.2097C20.3319 21.875 21.2317 20.9375 21.2317 19.7917V5.20833C21.2317 4.0625 20.3319 3.125 19.2097 3.125ZM17.4404 12.5C17.4404 12.7396 17.4202 12.9792 17.3898 13.2083L18.8861 14.4167C19.0176 14.5312 19.058 14.7292 18.967 14.8854L17.5516 17.4062C17.4606 17.5625 17.2786 17.625 17.1168 17.5625L15.3577 16.8333C14.9937 17.125 14.5893 17.3646 14.1646 17.5521L13.9018 19.4792C13.8714 19.6562 13.7198 19.7917 13.5479 19.7917H10.717C10.5452 19.7917 10.3935 19.6562 10.3632 19.4896L10.1003 17.5625C9.66556 17.375 9.27125 17.1354 8.90728 16.8438L7.14809 17.5729C6.98633 17.6354 6.80434 17.5729 6.71335 17.4167L5.29791 14.8958C5.20692 14.7396 5.24736 14.5417 5.37879 14.4271L6.87511 13.2187C6.84478 12.9792 6.82456 12.7396 6.82456 12.5C6.82456 12.2604 6.84478 12.0208 6.87511 11.7917L5.37879 10.5833C5.24736 10.4687 5.20692 10.2708 5.29791 10.1146L6.71335 7.59375C6.80434 7.4375 6.98633 7.375 7.14809 7.4375L8.90728 8.16667C9.27125 7.875 9.67567 7.63542 10.1003 7.44792L10.3632 5.52083C10.3935 5.34375 10.5452 5.20833 10.717 5.20833H13.5479C13.7198 5.20833 13.8714 5.34375 13.9018 5.51042L14.1646 7.4375C14.5994 7.625 14.9937 7.86458 15.3577 8.15625L17.1168 7.42708C17.2786 7.36458 17.4606 7.42708 17.5516 7.58333L18.967 10.1042C19.058 10.2604 19.0176 10.4583 18.8861 10.5729L17.3898 11.7812C17.4202 12.0208 17.4404 12.2604 17.4404 12.5Z" fill={active? "#FDE8FF" : "#646464"}/>
  </g>
  <defs>
    <clipPath id="clip0_632_103">
      <rect width="24.2647" height="25" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default AccessManagementIcon