import theme from "theme";

const styles = {
    mainContainer: {
      position: "fixed",
      width: "100%",
      top: 0,
      left: 0,
      zIndex: 121,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#D9D9D9',
    },
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: "475px",
      width: "100%",
      boxSizing: "border-box",
      textAlign: "center",
      alignItems: 'center',
      padding: '60px',
      borderRadius: '50px',
      backgroundColor: '#ffffff',
      boxShadow: '0px 0px 16.4px 0px rgba(0, 0, 0, 0.25)',
    },
    title: {
      marginBottom: '30px',
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
    },
    inputField: {
      marginBottom: '20px',
      width: '100%',
      borderRadius: "62px",
      background: "#D9D9D9",
      '& .MuiOutlinedInput-root': {
        borderRadius: '62px',
      },
    },
    loginButton: {
      borderRadius: "62px",
      backgroundColor: theme.purple,
      color: '#ffffff',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23px',
      marginTop: "45px",
      padding: '15px 40px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.purple,
      },
    },
    remember:{
      display: "flex",
      gap: 5,
      alignItems: "center"
    },
    label: {
      color: "#858585",
      fontFamily: "Poppins",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px"
    },
    forgetPassword: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    }
  };

  export default styles