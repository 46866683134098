import React from 'react'

const FilterIcon = ({width= 18, height = 18}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
  <path d="M0 3.44395C0.0333322 2.58579 0.405492 1.77583 1.03493 1.19157C1.66436 0.60731 2.49974 0.296393 3.358 0.326947H14.764C15.5784 0.298293 16.3709 0.593648 16.9679 1.1483C17.565 1.70295 17.9177 2.47163 17.949 3.28593C17.9443 3.74783 17.829 4.20192 17.6127 4.61009C17.3965 5.01826 17.0856 5.36865 16.706 5.63194L11.471 9.38798C11.2483 9.54102 11.0626 9.74194 10.9277 9.97606C10.7927 10.2102 10.7118 10.4715 10.691 10.7409L10.365 16.1009C10.3266 16.4368 10.206 16.758 10.014 17.0362C9.82198 17.3144 9.56438 17.541 9.26398 17.696C8.96357 17.851 8.62961 17.9296 8.29161 17.9249C7.95361 17.9202 7.62197 17.8322 7.326 17.6689L6.42601 17.201C6.03981 17.0071 5.71429 16.7109 5.48503 16.3446C5.25577 15.9783 5.13158 15.556 5.12601 15.1239V10.524C5.12384 10.293 5.07608 10.0647 4.98547 9.85228C4.89487 9.63983 4.76319 9.44736 4.59801 9.28593L0.889999 5.56096C0.609891 5.28382 0.387129 4.95422 0.234421 4.59098C0.0817128 4.22775 0.00205171 3.83798 0 3.44395ZM3.358 1.83397C2.90353 1.80767 2.4571 1.96215 2.11613 2.26378C1.77517 2.5654 1.56735 2.98967 1.53801 3.44395C1.53995 3.64254 1.58114 3.8388 1.65923 4.0214C1.73731 4.20401 1.85074 4.36939 1.993 4.50798L5.70601 8.23198C6.00947 8.53153 6.25089 8.88792 6.41647 9.28086C6.58206 9.67381 6.66855 10.0956 6.67101 10.522V15.1219C6.67598 15.2795 6.72436 15.4326 6.81082 15.5644C6.89728 15.6962 7.01846 15.8016 7.161 15.8689L8.061 16.337C8.14345 16.38 8.23491 16.4029 8.3279 16.4037C8.42088 16.4046 8.51277 16.3834 8.59601 16.342C8.6633 16.314 8.72168 16.2683 8.76486 16.2096C8.80804 16.1509 8.83437 16.0815 8.841 16.009L9.16701 10.649C9.20171 10.1569 9.34597 9.67889 9.58923 9.2498C9.8325 8.8207 10.1686 8.45138 10.573 8.16893L15.808 4.41295C15.992 4.28735 16.1433 4.11947 16.2491 3.92338C16.3549 3.72729 16.4121 3.5087 16.416 3.28593C16.3887 2.87569 16.2004 2.49285 15.8921 2.22087C15.5838 1.94888 15.1804 1.80984 14.77 1.83397H3.358Z" fill="#17181A"/>
    </svg>
  )
}

export default FilterIcon