import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Checkbox, Modal, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import theme from 'theme';
import CrossIcon from 'assets/icons/CrossIcon';

const styles = {
    mainContainer: {
        display: "flex",
        gap: 13,
    },
    listContainer: {
        borderRadius: "6px",
        width: "100%",
        maxWidth: 320,
        minWidth: 320,
        maxHeight: 180,
        overflow: "scroll",
        boxSizing: "border-box",
        minHeight: 100,
        border: `1px solid ${theme.purple}`,
        background: "rgba(255, 255, 255, 0.00)",
        padding: 10
    },
    addModal: {
        outline: "none",
        borderRadius: "6px",
        background: "#FFF",
        padding: 15,
        width: "292px",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
      },
      applyButton: {
        float: "right",
        borderRadius: '12px',
        border: `1px solid ${theme.purple}`,
        background: theme.purple,
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '8px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '23.4px',
        textTransform: 'capitalize',
        padding: 0,
      },
      modalHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      modalTitle:{
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 700,
        color: theme.purple
      },
      inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        marginBottom: '20px',
      },
      label: {
        minWidth: '103px',
        maxWidth: '103px',
        whiteSpace: 'wrap',
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '28px',
      },
      input: {
        width: '100%',
        padding: '10px',
        borderRadius: '6px',
        border: '1px solid #6D0185',
        background: 'rgba(255, 255, 255, 0.00)',
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '28px',
      },
      listItem: {
        width: "100%",
        maxWidth: 300,
        marginBottom: 5,
        borderRadius: "8px",
        border: `1px solid ${theme.purple}`
      },
      listText: {
        color: "#000",
        fontFamily: 'Poppins',
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
      },
      addButton: {
        borderRadius: "6px",
        background: theme.purple,
        color: "#FFF",
        fontFamily: 'Poppins',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        maxHeight: 24,
      }
}

const BenifitsAndPerks = ({ tasks, setTasks }) => {
  const [task, setTask] = useState([]);
  const [benifitsModal, setBenifitsModal] = useState(false);

  const handleAddTask = () => {
    if (task.trim()) {
      setTasks([...tasks, task]);
      setTask('');
      setBenifitsModal(false); 
    }
  };

  const handleDeleteTask = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Box style={styles.mainContainer}>
        <List style={styles.listContainer}>
          {tasks.length> 0 && tasks.map((task, index) => (
            <ListItem key={index} style={{ display: 'flex', alignItems: 'center', ...styles.listItem }}>
              <ListItemText
                primary={task}
                style={styles.listText}
              />
              <Box style={{cursor: "pointer"}} onClick={() => handleDeleteTask(index)}>
                <CrossIcon />
              </Box>
            </ListItem>
          ))}
        </List>
        <Button
          style={styles.addButton}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setBenifitsModal(true)}  
        >
          Add
        </Button>
      </Box>
      <Modal
        open={benifitsModal}
        onClose={() => setBenifitsModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Typography style={styles.modalTitle}>ADD BENIFITS AND PERKS</Typography>
            <Box style={{cursor: "pointer"}} onClick={() => setBenifitsModal(false)}>
              <CrossIcon />
            </Box>
          </Box>
          <Box style={{...styles.inputContainer, gap: 10, marginTop: 25}}>
            <label style={styles.label}>Benifit Name</label>
            <input type="text" style={{ ...styles.input, maxWidth: '191px' }} 
              value={task}
              onChange={(e) => setTask(e.target.value)} 
            />
          </Box>
          <Button variant="contained" style={styles.applyButton} onClick={handleAddTask}>
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default BenifitsAndPerks;
