import React from 'react'

const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 10 18" fill="none">
  <path d="M9.25029 1L1.25 9.00029L9.25029 17.0006" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default BackIcon