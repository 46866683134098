import React, { useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import styles from 'assets/styles/experienceFilter';
import { useNavigate } from 'react-router-dom';

const ExperienceFilter = ({ filter, setFilter, years, setYears }) => {

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleYearsChange = (event) => {
    setYears(event.target.value);
  };

  return (
    <Box style={styles.filterBoxContainer}>
      <Typography style={styles.filterTitle}>Experience</Typography>
    <Box style={styles.filterBox}>
      <Typography style={styles.label}>Filter by</Typography>
      <RadioGroup value={filter} onChange={handleFilterChange}>
        <Box style={styles.radioGroup}>
          <FormControlLabel
            value="less_than_x_years"
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, color: '#5C5C5C' }} />}
            label={<Typography style={styles.radioLabel}>Less than</Typography>}
          />
          <FormControlLabel
            value="more_than_x_years"
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, color: '#5C5C5C' }} />}
            label={<Typography style={styles.radioLabel}>Greater than</Typography>}
          />
        </Box>
      </RadioGroup>

      <Box style={styles.experienceBox}>
        <Box style={styles.inputBox}>
          <input 
            type="number" 
            style={styles.numberInput} 
            value={years}
            onChange={handleYearsChange} 
          />
          <Typography style={{...styles.radioLabel, marginLeft: "8px"}}>Years</Typography>
        </Box>
      </Box>
      </Box>
      </Box>
  );
};

export default ExperienceFilter;
