import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if(token){
      return children;
  }
  return <Navigate to="/login" />
};

export default PrivateRoute;
