import React from 'react'

const LockIcon = ({width = 47, height = 38}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 47 38" fill="none">
    <path d="M29.1842 5.39862V5.89862H29.6842H45.7632C46.1649 5.89862 46.5 6.23178 46.5 6.64183V36.4789C46.5 36.8889 46.1649 37.2221 45.7632 37.2221H1.23684C0.835139 37.2221 0.5 36.8889 0.5 36.4789V6.64183C0.5 6.23178 0.835139 5.89862 1.23684 5.89862H17.3158H17.8158V5.39862V1.66899C17.8158 1.25894 18.1509 0.925781 18.5526 0.925781H28.4474C28.8491 0.925781 29.1842 1.25894 29.1842 1.66899V5.39862ZM19.7895 2.4122H19.2895V2.9122V5.39862V5.89862H19.7895H27.2105H27.7105V5.39862V2.9122V2.4122H27.2105H19.7895ZM44.5263 35.7357H45.0263V35.2357V22.8036V22.3036H44.5263H27.2105H26.7105V22.8036V26.471C26.7105 26.8811 26.3754 27.2142 25.9737 27.2142H21.0263C20.6246 27.2142 20.2895 26.8811 20.2895 26.471V22.8036V22.3036H19.7895H2.47368H1.97368V22.8036V35.2357V35.7357H2.47368H44.5263ZM21.7632 25.2278V25.7278H22.2632H24.7368H25.2368V25.2278V17.7686V17.2686H24.7368H22.2632H21.7632V17.7686V25.2278ZM44.5263 20.8171H45.0263V20.3171V7.88504V7.38504H44.5263H2.47368H1.97368V7.88504V20.3171V20.8171H2.47368H19.7895H20.2895V20.3171V16.5875C20.2895 16.1775 20.6246 15.8443 21.0263 15.8443H25.9737C26.3754 15.8443 26.7105 16.1775 26.7105 16.5875V20.3171V20.8171H27.2105H44.5263Z" fill="#6D0185" stroke="#6D0185"/>
  </svg>
  )
}

export default LockIcon