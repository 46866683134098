import React from 'react';
import { Box } from '@mui/material';
import styles from 'assets/styles/footer';

const Footer = () => {

  return (
    <Box sx={styles.container}>
      <div style={styles.text}>&copy; Fanisko 2024</div>
    </Box>
  );
};

export default Footer;
