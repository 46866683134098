import theme from "theme";

const styles = {
    overlayContainer: {
      background: "rgba(136, 136, 136, 0.79)",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1111,
    },
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: "826px",
      maxHeight: "90vh",
      overflowY: "auto",
      background: "#fff",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      borderRadius: "33px",
      boxShadow: "0px 4px 10.7px 0px rgba(0, 0, 0, 0.25)",
      paddingTop: "49px",
      paddingBottom: "74px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    thankyouText: {
      marginTop: "18px",
      color: theme.purple,
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "23.4px"
    },
    para: {
      color: "#202124",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "275",
      lineHeight: "23.4px"
    },
    dismissBtn: {
      marginTop: "38px",
      borderRadius: "12px",
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "28px",
      textTransform: "capitalize",
      paddingLeft: "22px",
      paddingRight: "20px"
    }
  };

  export default styles