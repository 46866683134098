import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from 'assets/icons/DashboardIcon';
import ApplicationIcon from 'assets/icons/ApplicationIcon';
import JobOpenningIcon from 'assets/icons/JobOpenningIcon';
import FilesIcon from 'assets/icons/FilesIcon';
import AccessManagementIcon from 'assets/icons/AccessManagementIcon';
import styles from 'assets/styles/dashboardSidebar';
import { useNavigate } from 'react-router-dom';

const DashboardSidebar = ({ page }) => {
  const menuItems = [
    { name: 'Dashboard', icon: <DashboardIcon active={page==="Dashboard"} />, link: "/dashboard" },
    { name: 'Applications', icon: <ApplicationIcon active={page==="Applications"} />,link: "/dashboard/applications"  },
    { name: 'Job Openings', icon: <JobOpenningIcon active={page==="Job Openings"} />, link: "/dashboard/job-opennings" },
    { name: 'Files', icon: <FilesIcon active={page==="Files"} />, link: "/dashboard/files" },
    { name: 'Access Management', icon: <AccessManagementIcon active={page==="Access Management"} />, link: "/dashboard/access-management" }
  ];
  const [fullWidth, setFullWidth] = useState(false)
  const navigate = useNavigate()

  return (
    <div style={{...styles.sidebar, minWidth: fullWidth ? "256px" : "unset"}} onMouseEnter={() => setFullWidth(true)} onMouseLeave={() => setFullWidth(false)}>
      <List style={styles.list}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.name}
            style={{
              ...styles.listItem,
              ...(page === item.name && styles.activeListItem)
            }}
            onClick={()=> navigate(`${item.link}`)}
          >
            <ListItemIcon style={{...styles.listItemIcon, minWidth: fullWidth ? "44px" : "24px"}}>
              {item.icon}
            </ListItemIcon>
            {fullWidth &&
             <ListItemText
              primary={item.name}
              style={{
                ...styles.listItemText,
                ...(page === item.name && styles.activeListItemText)
              }}
            />
            }
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DashboardSidebar;
