import React from 'react'

const TickIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M15 28C22.15 28 28 22.15 28 15C28 7.85 22.15 2 15 2C7.85 2 2 7.85 2 15C2 22.15 7.85 28 15 28Z" stroke="#6D0185" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 15L13.3294 19L20 11" stroke="#6D0185" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default TickIcon