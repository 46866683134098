import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, MenuItem, Modal, Paper, Select, Typography } from '@mui/material';
import styled from 'styled-components';
import DashboardSidebar from 'components/DashboardSidebar';
import theme from 'theme';
import addIcon from 'assets/images/addIcon.png';
import CrossIcon from 'assets/icons/CrossIcon';
import JoditEditor from 'jodit-react';
import BenifitsAndPerks from 'components/BenifitsAndPerks';
import styles from 'assets/styles/addJobListing';
import axios from 'axios';
import { useAppContext } from 'context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import validateToken from 'services/validateToken';
import { toast, ToastContainer } from 'react-toastify';
import countries from 'assets/constants/countries';

const AddJobListing = () => {
  const token = localStorage.getItem('token');
  const {departments, updateJobProfile, seniorityLevels, locations, refreshDepartments, refreshLocations, refreshSeniorityLevels } = useAppContext()
  const location = useLocation();
  const { application } = location.state || {};
  const [newCountryCode, setNewCountryCode] = useState('');
  const formatString = (value) => {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); 
    const day = String(dateObj.getDate()).padStart(2, '0');
    return`${year}-${month}-${day}`;
  }
  const [tasks, setTasks] = useState(Array.isArray(application?.benefits_and_perks) ? application?.benefits_and_perks: JSON.parse(application?.benefits_and_perks))
  const [formData, setFormData] = useState({
    job_id: application?.job_id,
    job_title: application?.job_title,
    no_of_positions: application?.no_of_positions,
    target_date: formatString(application?.target_date),
    location: application?.location,
    seniority_level: application?.seniority_level,
    department_name: application?.department_name,
    job_type: application?.job_type,
    summary: application?.summary,
    job_description: application?.job_description,
    minimum_questions: application?.minimum_questions,
    about_us: application?.about_us,
    benefits_and_perks: tasks
  });
  const [addDepartment, setAddDepartment] = useState(false);
  const [seniorityModal, setSeniorityModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [newDepartment, setNewDepartment] = useState('');
  const [newSeniorityLevel, setNewSeniorityLevel] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const navigate = useNavigate()
  
  const jobDescriptionEditor = useRef();
  const minQuestionEditor = useRef();
  const aboutEditor = useRef();

  const config = useMemo(() => ({
    readonly: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    placeholder: ''
  }), []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleJobType = (type) => {
    setFormData((prevData) => ({
      ...prevData,
      job_type: type
    }));
  };

  const handleSave = async () => {
      const updatedFormData = {
        ...formData,
        benefits_and_perks: tasks
      }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/edit`, updatedFormData, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        });
      toast.success('Job saved successfully');
      await updateJobProfile();
      navigate('/dashboard/job-opennings')
    } catch (error) {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        handleSave()
      }
      console.error('Error saving job:', error);
    }
  };

  const handleAddDepartment = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/departments/add`, 
        { department_name: newDepartment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setNewDepartment('');
      setAddDepartment(false);
      await refreshDepartments();
    } catch (error) {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        handleAddDepartment()
      }
      console.error('Error adding department:', error);
    }
  };

  const handleAddSeniorityLevel = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/seniority-levels/add`, { seniority_level: newSeniorityLevel },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewSeniorityLevel('');
      setSeniorityModal(false);
      await refreshSeniorityLevels();
    } catch (error) {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        handleAddSeniorityLevel()
      }
      console.error('Error adding seniority level:', error);
    }
  };
   const handleAddLocation = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/locations/add`, 
        { location: newLocation,
          country_code: newCountryCode,
         },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewLocation('');
      setNewCountryCode('');
      setLocationModal(false);
      await refreshLocations();
    } catch (error) {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        handleAddLocation()
      }
      console.error('Error adding location:', error);
    }
   };
  const handleSaveDisable = () => {
    if ((formData.job_title === '' || formData.job_title === null) || (formData.no_of_positions === '' || formData.no_of_positions === null) || (formData.target_date === '' || formData.target_date === null) || (formData.department_name === '' || formData.department_name === null) || (formData.seniority_level === '' || formData.seniority_level === null) || (formData.location === '' || formData.location === null) || (formData.summary === '' || formData.summary === null) || (formData.job_description === '' || formData.job_description === null) || (formData.minimum_questions === '' || formData.minimum_questions === null) || (formData.about_us === '' || formData.about_us === null) || (tasks.length < 1 || formData.benefits_and_perks === null)) {
      setSaveDisabled(true);
      return true;
    }
    setSaveDisabled(false);
    return false;
  }
  useEffect(() => { handleSaveDisable() }, [formData, tasks])
  
  const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  }));


  return (
    <Box style={styles.dashboard}>
      <ToastContainer/>
      <DashboardSidebar page="Job Openings" />
      <Box style={styles.container}>
      <Typography variant="h4" style={styles.heading}>Add Job Listing</Typography>
        <Paper style={styles.formContainer}>
          <form style={styles.form}>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Job ID</label>
              <input type="text" name="job_id" style={{ ...styles.input, maxWidth: '191px' }} value={formData.job_id} disabled />
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Job Title</label>
              <Box style={{width: "100%"}}>
                <input type="text" name="job_title" style={{ ...styles.input, maxWidth: '191px' }} value={formData.job_title} onChange={handleChange} />
                {formData.job_title === '' && <Typography color="red">Job Title is required</Typography>}
              </Box>
            </Box>
            <Box style={{ ...styles.inputContainer, marginBottom: 0 }}>
              <Box style={styles.inputContainer}>
                <label style={styles.label}>No of positions</label>
                <Box style={{ width: "100%" }}>
                  <input
                  type="number"
                  name="no_of_positions"
                  style={{ ...styles.input, maxWidth: '72px' }}
                  value={formData.no_of_positions}
                  onInput={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                    onChange={handleChange} />
                {formData.no_of_positions === '' && <Typography color="red">no of positions is required</Typography>}
                </Box>
              </Box>
              <Box style={styles.inputContainer}>
                <label style={styles.label}>Target Date</label>
                <Box style={{ width: "100%" }}>
                  <input
                    type="date"
                    name="target_date"
                    style={{ ...styles.input, maxWidth: '129px' }}
                    value={formData.target_date}
                    onChange={handleChange}
                    required
                    min={new Date().toISOString().split("T")[0]} 
                  />
                {formData.target_date === '' && <Typography color="red">target date is required</Typography>}
                </Box>
              </Box>
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Department name</label>
              <CustomSelect
                name="department_name"
                value={formData.department_name}
                style={styles.selectInput}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                <MenuItem style={styles.MenuItem} value="" disabled>Select Department</MenuItem>
                {departments
                  .filter((item) => item.trim() !== '')
                  .map((item) => (
                    <MenuItem style={styles.MenuItem} value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))
                }
              </CustomSelect>
              <img style={{ cursor: "pointer" }} src={addIcon} onClick={() => setAddDepartment(true)} />
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Seniority level</label>
              <CustomSelect
                name="seniority_level"
                value={formData.seniority_level}
                style={styles.selectInput}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                <MenuItem style={styles.MenuItem} value="" disabled>Select Level</MenuItem>
                {seniorityLevels.filter((item) => item.trim() !== '').map((item)=>(
                  <MenuItem style={styles.MenuItem} value={item}>{item}</MenuItem>
                ))}
              </CustomSelect>
              <img style={{ cursor: "pointer" }} src={addIcon} onClick={() => setSeniorityModal(true)} />
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Job Type</label>
              <JobTypeContainer>
                <Button
                  style={{ ...styles.jobTypeButton, ...styles.onSiteBtn, background: formData.job_type === 'Onsite' ? theme.purple : "unset", color: formData.job_type === 'Onsite' ? "#FFF" : "#000" }}
                  onClick={() => handleJobType("Onsite")}
                >
                  Onsite
                </Button>
                <Button
                  style={{ ...styles.jobTypeButton, background: formData.job_type === 'Hybrid' ? theme.purple : "none", color: formData.job_type === 'Hybrid' ? "#FFF" : "#000" }}
                  onClick={() => handleJobType("Hybrid")}
                >
                  Hybrid
                </Button>
                <Button
                  style={{ ...styles.jobTypeButton, ...styles.remoteBtn, background: formData.job_type === 'Remote' ? theme.purple : "none", color: formData.job_type === 'Remote' ? "#FFF" : "#000" }}
                  onClick={() => handleJobType("Remote")}
                >
                  Remote
                </Button>
              </JobTypeContainer>
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Location</label>
              <CustomSelect
                name="location"
                value={formData.location}
                style={styles.selectInput}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                <MenuItem style={styles.MenuItem} value="" disabled>Select Location</MenuItem>
                {locations.filter((item) => item.location.trim() !== '').map((item)=>(
                  <MenuItem style={styles.MenuItem} value={`${item.location}, ${item.country_code}`}>{item.location}, {item.country_code}</MenuItem>
                ))}
              </CustomSelect>
              <img style={{ cursor: "pointer" }} src={addIcon} onClick={() => setLocationModal(true)} />
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Summary</label>
              <Box style={{width: "100%"}}>
                <textarea rows={4} name="summary" style={styles.textarea} value={formData.summary} onChange={handleChange} />
                {formData.summary === '' && <Typography color="red">summary is required</Typography>}
              </Box>
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>What will the candidate do?</label>
              <Box style={{width: "100%"}}>
                <Box style={styles.textEditorArea}>
                  <JoditEditor
                    ref={jobDescriptionEditor}
                    value={formData.job_description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setFormData({ ...formData, job_description: newContent })}
                    onChange={(newContent) => {setFormData({ ...formData, job_description: newContent })}}
                  />
                </Box>
                {formData.job_description === '' && <Typography color="red">job description is required</Typography>}
              </Box>  
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>What should the candidate already have?</label>
                <Box style={{width: "100%"}}>
                  <Box style={styles.textEditorArea}>
                    <JoditEditor
                      ref={minQuestionEditor}
                      value={formData.minimum_questions}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setFormData({ ...formData, minimum_questions: newContent })}
                      onChange={(newContent) => setFormData({ ...formData, minimum_questions: newContent })}
                    />
                  </Box>
                  {formData.minimum_questions === '' && <Typography color="red">minimum questions are required</Typography>}
                </Box> 
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>About us</label>
              <Box style={{width: "100%"}}>
                <Box style={styles.textEditorArea}>
                  <JoditEditor
                    ref={aboutEditor}
                    value={formData.about_us}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setFormData({ ...formData, about_us: newContent })}
                    onChange={(newContent) => setFormData({ ...formData, about_us: newContent })}
                  />
                </Box>
                  {formData.about_us === '' && <Typography color="red">About Us is required</Typography>}
              </Box>
            </Box>
            <Box style={styles.inputContainer}>
              <label style={styles.label}>Benefits and perks</label>
              <BenifitsAndPerks tasks={tasks} setTasks={setTasks}  />
            </Box>
            <Box style={styles.buttonContainer}>
              <Button variant="contained" color="primary" style={{...styles.submitButton, opacity: saveDisabled ? 0.5 : 1, cursor: saveDisabled ? "not-allowed": "pointer" }} onClick={handleSave} disabled={saveDisabled}>
                Save
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
      <Modal
        open={addDepartment}
        onClose={() => setAddDepartment(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Typography style={styles.modalTitle}>ADD DEPARTMENT</Typography>
            <Box style={{cursor: "pointer"}} onClick={() => setAddDepartment(false)}>
              <CrossIcon />
            </Box>
          </Box>
          <Box style={{...styles.inputContainer, gap: 10, marginTop: 25}}>
              <label style={styles.label}>Department name</label>
              <input type="text" style={{ ...styles.input, maxWidth: '191px' }} value={newDepartment} onChange={(e)=> setNewDepartment(e.target.value)} />
            </Box>
          <Button variant="contained" style={styles.applyButton} onClick={handleAddDepartment} disabled={newDepartment === ''}>
              Save
          </Button>
        </Box>
      </Modal>
      <Modal
        open={seniorityModal}
        onClose={() => setSeniorityModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Typography style={styles.modalTitle}>ADD SENIORITY</Typography>
            <Box style={{cursor: "pointer"}} onClick={() => setSeniorityModal(false)}>
              <CrossIcon />
            </Box>
          </Box>
          <Box style={{...styles.inputContainer, gap: 10, marginTop: 25}}>
              <label style={styles.label}>Add seniority level</label>
              <input type="text" style={{ ...styles.input, maxWidth: '191px' }} value={newSeniorityLevel} onChange={(e)=> setNewSeniorityLevel(e.target.value)} />
            </Box>
          <Button variant="contained" style={styles.applyButton} onClick={handleAddSeniorityLevel} disabled={newSeniorityLevel === ''}>
              Save
          </Button>
        </Box>
      </Modal>
       <Modal
        open={locationModal}
        onClose={() => setLocationModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Typography style={styles.modalTitle}>ADD LOCATION</Typography>
            <Box style={{cursor: "pointer"}} onClick={() => setLocationModal(false)}>
              <CrossIcon />
            </Box>
          </Box>
          <Box style={{...styles.inputContainer, gap: 10, marginTop: 25}}>
              <label style={styles.label}>Add city name</label>
              <input type="text" style={{ ...styles.input, maxWidth: '191px' }} value={newLocation} onChange={(e)=> setNewLocation(e.target.value)} placeholder='(Eg. Mumbai, Chicago, London)' />
            </Box>
            <Box style={{...styles.inputContainer, gap: 10, marginTop: 16}}>
              <label style={styles.label}>Country start code</label>
            {/* <input type="text" style={{ ...styles.input, maxWidth: '191px' }} value={newCountryCode} onChange={(e)=> setNewCountryCode(e.target.value)} placeholder='(Eg. IN, US, SG, CAN)' /> */}
              <CustomSelect
                name="country-code"
                value={newCountryCode}
                style={styles.selectInput}
                onChange={(e) => setNewCountryCode(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                <MenuItem style={styles.MenuItem} value="" disabled>Select Country</MenuItem>
                {countries.filter((item) => item.name.trim() !== '').map((item)=>(
                  <MenuItem style={styles.MenuItem} value={item.code}>{item.name}, {item.code}</MenuItem>
                ))}
              </CustomSelect>
            </Box>
            <Button variant="contained" style={styles.submitButton} onClick={handleAddLocation} disabled={newLocation === '' || newCountryCode === ''}>
                Save
              </Button>
        </Box>
      </Modal>
    </Box>
  );
};
const JobTypeContainer = styled.div`
  display: flex;
  border-radius: 50px;
  border: 1px solid ${theme.purple};
  background: rgba(255, 255, 255, 0.00);
`;

export default AddJobListing;