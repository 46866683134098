import React, { useState } from 'react';
import { Box, Typography, InputBase, Button, Autocomplete, TextField } from '@mui/material';
import theme from 'theme';
import SearchIcon from 'assets/icons/SearchIcon';
import LocationIcon from 'assets/icons/LocationIcon';
import styles from 'assets/styles/jobSearchBox';
import { useAppContext } from 'context/AppContext';

const JobSearchBox = ({ totalJobs, jobOpennings, setJobOpennings }) => {
  const { departments, seniorityLevels, locations } = useAppContext();
  const filteredLocations = locations.map(loc => `${loc.location}, ${loc.country_code}`);
  const [selectedCities, setSelectedCities] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedSeniorityLevels, setSelectedSeniorityLevels] = useState([]);
  const [autoCompleteFocusCitiesCss, setAutoCompleteFocusCitiesCss] = useState({});
  const [autoCompleteFocusDepartmentCss, setAutoCompleteFocusDepartmentCss] = useState({});
  const [autoCompleteFocusSeniorityCss, setAutoCompleteFocusSeniorityCss] = useState({});

  const handleCityChange = (event, values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedCities(uniqueValues);
  };

  const handleDepartmentChange = (event, values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedDepartments(uniqueValues);
  };

  const handleSeniorityChange = (event, values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedSeniorityLevels(uniqueValues);
  };

  const handleSearch = () => {
    const filteredData = jobOpennings.filter(jobOpening => {
      const titleMatches = jobOpening.job_title.toLowerCase().includes(query.toLowerCase());
      const cityMatches = selectedCities.length > 0 ? selectedCities.includes(jobOpening.location) : true;
      const departmentMatches = selectedDepartments.length > 0 ? selectedDepartments.includes(jobOpening.department_name) : true;
      const seniorityMatches = selectedSeniorityLevels.length > 0 ? selectedSeniorityLevels.includes(jobOpening.seniority_level) : true;
      return titleMatches && cityMatches && departmentMatches && seniorityMatches;
    });
    setJobOpennings(filteredData);
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h1" sx={styles.title}>
        There are <Typography component="span" sx={styles.jobCount}>{totalJobs}</Typography> jobs available
      </Typography>
      <Typography variant="body1" sx={styles.subtitle}>Find Jobs, Employment & Career Opportunities</Typography>
      <Box sx={styles.searchContainer}>
        <Box sx={styles.inputContainer}>
          <SearchIcon />
          <InputBase placeholder="Enter job title or keywords" sx={styles.input} value={query} onChange={(e) => setQuery(e.target.value)} />
        </Box>
        <Box sx={styles.selectContainer}>
          <LocationIcon />
          <Autocomplete
            multiple
            disablePortal
            sx={{ ...styles.selectOption, ...styles.autocompleteContainer, ...autoCompleteFocusCitiesCss }}
            options={filteredLocations.filter(city => !selectedCities.includes(city))}
            value={selectedCities}
            onChange={handleCityChange}
            onFocus={() => setAutoCompleteFocusCitiesCss({ paddingTop: "10px" })}
            onBlur={() => setAutoCompleteFocusCitiesCss({})}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="Cities" />}
          />
        </Box>
        <Box sx={styles.searchButtonContainer}>
          <Button variant="contained" sx={styles.searchButton} onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Box>
      <Box sx={styles.filterBox}>
        <Autocomplete
          multiple
          disablePortal
          sx={{ ...styles.filterSelect, ...styles.autocompleteContainer, ...autoCompleteFocusDepartmentCss }}
          options={departments.filter(department => !selectedDepartments.includes(department))}
          value={selectedDepartments}
          onChange={handleDepartmentChange}
          onFocus={()=> setAutoCompleteFocusDepartmentCss({ paddingTop: "10px" })}
          onBlur={()=> setAutoCompleteFocusDepartmentCss({})}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Department" />}
        />
        <Autocomplete
          multiple
          disablePortal
          sx={{ ...styles.filterSelect, ...styles.autocompleteContainer, ...autoCompleteFocusSeniorityCss }}
          options={seniorityLevels.filter(seniorityLevel => !selectedSeniorityLevels.includes(seniorityLevel))}
          value={selectedSeniorityLevels}
          onChange={handleSeniorityChange}
          onFocus={()=> setAutoCompleteFocusSeniorityCss({ paddingTop: "10px" })}
          onBlur={()=> setAutoCompleteFocusSeniorityCss({})}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Seniority Level" />}
        />
      </Box>
    </Box>
  );
};

export default JobSearchBox;
