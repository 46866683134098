import React from 'react'

const GlobalIcon = ({width= 23, height= 21}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23 21" fill="none">
  <path d="M11.4365 19.5653C17.1934 19.5653 21.8603 15.3464 21.8603 10.1422C21.8603 4.93806 17.1934 0.719238 11.4365 0.719238C5.67968 0.719238 1.01282 4.93806 1.01282 10.1422C1.01282 15.3464 5.67968 19.5653 11.4365 19.5653Z" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M1.53399 10.6134L7.26704 12.498L6.22467 15.7961L7.26704 18.623" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M16.6484 18.1518L16.1272 15.7961L13.5213 14.8538V11.5557L16.6484 10.6134L21.3391 11.0846" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M18.7331 4.01727L18.2119 5.43072L14.5636 5.90187V8.72878L17.1696 7.78648H19.2543L21.3391 8.72878" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M1.53399 8.72876L4.13992 6.84415L6.74586 6.373L8.8306 3.5461L7.78823 1.6615" stroke="#6D0185" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default GlobalIcon