import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ApplicationForm from './pages/ApplicationForm';
import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import 'App.css';
import WebFont from 'webfontloader';
import theme from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import { AppProvider } from 'context/AppContext';
import DesktopOnly from './components/DesktopOnly';
import Dashboard from 'pages/Dashboard';
import Applications from 'pages/Dashboard/applications';
import JobOpennings from 'pages/Dashboard/jobOpennings';
import Files from 'pages/Dashboard/files';
import AccessManagement from 'pages/Dashboard/accessManagement';
import AddJobListing from 'pages/Dashboard/addJobListing';
import PrivateRoute from './components/PrivateRoute';
import EditJobListing from 'pages/Dashboard/editJobListing';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins:275,300,400,500,600,700'],
      },
    });
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <DesktopOnly>
          <Header />
          <AppProvider>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/job/:id" element={<ApplicationForm />} />
              <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/applications"
              element={
                <PrivateRoute>
                  <Applications />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/job-opennings"
              element={
                <PrivateRoute>
                  <JobOpennings />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/files"
              element={
                <PrivateRoute>
                  <Files />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/access-management"
              element={
                <PrivateRoute>
                  <AccessManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/job-opennings/add-new-job"
              element={
                <PrivateRoute>
                  <AddJobListing />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/job-opennings/edit-job"
              element={
                <PrivateRoute>
                  <EditJobListing />
                </PrivateRoute>
              }
            />
          </Routes>
          </AppProvider>
          <Footer />
        </DesktopOnly>
      </ThemeProvider>
    </Router>
  );
}

export default App;
