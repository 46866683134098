import React from 'react'

const ApplyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="31" viewBox="0 0 15 31" fill="none">
  <path d="M14.1211 8.62891V16.5684H13.2422V10.123L4.13086 19.2344L3.51562 18.5898L12.5977 9.50781H6.15234V8.62891H14.1211Z" fill="white"/>
</svg>
  )
}

export default ApplyIcon