import React from 'react'

const SearchIcon = ({color="#696969"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
  <g clipPath="url(#clip0_55_59)">
    <path d="M20.7747 19.5811C20.9369 19.7433 21.018 19.9324 21.018 20.1487C21.018 20.3649 20.9369 20.5541 20.7747 20.7162C20.6126 20.8784 20.4234 20.9595 20.2072 20.9595C19.991 20.9595 19.8018 20.8784 19.6396 20.7162L14.572 15.6487C12.9504 17 11.0856 17.6757 8.97745 17.6757C6.57204 17.6757 4.51799 16.8243 2.81528 15.1216C1.11258 13.4189 0.26123 11.3581 0.26123 8.9392C0.26123 6.52028 1.11258 4.45947 2.81528 2.75677C4.51799 1.05407 6.57204 0.202717 8.97745 0.202717C10.572 0.202717 12.0383 0.594608 13.3761 1.37839C14.7139 2.16218 15.7747 3.22299 16.5585 4.56083C17.3423 5.89866 17.7342 7.36488 17.7342 8.95947C17.7342 11.0406 17.0585 12.8919 15.7072 14.5135L20.7747 19.5811ZM3.97069 3.91218C3.97069 3.91218 3.62272 4.26015 2.92677 4.95609C2.23082 5.65204 1.88285 6.97974 1.88285 8.9392C1.88285 10.8987 2.5788 12.5743 3.97069 13.9662C5.36258 15.3581 7.03826 16.0541 8.99772 16.0541C10.9572 16.0541 12.6329 15.3581 14.0247 13.9662C15.4166 12.5743 16.1126 10.8987 16.1126 8.9392C16.1126 6.97974 15.4166 5.30407 14.0247 3.91218C12.6329 2.52028 10.9572 1.82434 8.99772 1.82434C7.03826 1.82434 5.36258 2.52028 3.97069 3.91218Z" fill={color} />
  </g>
  <defs>
    <clipPath id="clip0_55_59">
      <rect width="21" height="21" fill="white" transform="matrix(1 0 0 -1 0.139603 21)"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default SearchIcon