import { Padding } from '@mui/icons-material'
import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import ExperienceFilter from 'components/ExperienceFilter'
import NoticePeriodFilter from 'components/NoticePeriodFilter'
import React, { useState } from 'react'
import theme from 'theme'

const FilterModal = ({ applications, setFilteredApplications, visible, setVisible, fetchApplications }) => {
  const [filter, setFilter] = useState('more_than_x_years');
  const [years, setYears] = useState(0);
  const [noticeFilter, setNoticeFilter] = useState('more_than_x_days');
  const [days, setDays] = useState(0);
    const [mainStatus, setMainStatus] = useState("All");
    if (!visible) {
        return (
          null
      )
    }
    
const handleApply = async () => {
  setVisible(false)
  try {
    let filteredApplications = applications.filter((application) => {
      if (filter === 'less_than_x_years') {
        return application.experience < Number(years);
      } else if (filter === 'more_than_x_years') {
        return application.experience > Number(years);
      }
      return true;
    });

    filteredApplications = filteredApplications.filter((application) => {
      if (noticeFilter === 'less_than_x_days') {
        return application.notice_period < days;
      } else if (noticeFilter === 'more_than_x_days') {
        return application.notice_period > days;
      }
      return true;
    });

    if (mainStatus !== "All") {
      filteredApplications = filteredApplications.filter(
        (application) => application.status === mainStatus
      );
    }

    setFilteredApplications(filteredApplications);
  } catch (error) {
    console.error('Error:', error);
  }
};

    const handleReset = async () => { 
        setVisible(false)
        await fetchApplications()
        setYears(0);
        setDays(0);
    }
    
    return (
    <Box style={styles.filterModalContainer}>
      <Box style={styles.filterModal}>
          <ExperienceFilter filter={filter} setFilter={setFilter} years={years} setYears={setYears} />
          <NoticePeriodFilter filter={ noticeFilter} setFilter={setNoticeFilter} days={days} setDays={setDays} />
          <Box style={styles.statusFilterContainer}>
              <Typography style={styles.filterTitle}>Status</Typography>
              <Box>
                <Select style={styles.setStatus} value={mainStatus} onChange={(e)=> setMainStatus(e.target.value)}>
                    <MenuItem style={styles.statusItem} value="All">All</MenuItem>
                    <MenuItem style={styles.statusItem} value="New">New</MenuItem>
                    <MenuItem style={styles.statusItem} value="Shortlisted">Shortlisted</MenuItem>
                    <MenuItem style={styles.statusItem} value="Rejected">Rejected</MenuItem>
                    <MenuItem style={styles.statusItem} value="Hired">Hired</MenuItem>
                  </Select>
              </Box>
          </Box>

      </Box>
        <Box style={styles.buttonBox}>
        <Button variant="contained" style={styles.cancelButton} onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" style={styles.applyButton} onClick={handleApply}>
          Apply
        </Button>
      </Box>
      </Box>
  )
}

const styles = {
    filterModalContainer: {
        borderRadius: "8px",
        border: "0.75px solid #646464",
        background: "#FFF",
        position: "absolute",
        top: 70,
        padding: "15px",
        zIndex: 11,
    },
    filterModal: {
        display: "flex",
        gap: "18px",
    },
      statusFilterContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8.4px"
  },
  filterTitle: {
          color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23.4px',
    },
      setStatus: {
      borderRadius: `25.347px`,
      border: `0.551px solid #BEA1FF`,
      minWidth: 105.47,
      maxWidth: 105.47,
      maxHeight: 37,
      color: "#5C5C5C",
      fontFamily: "Poppins",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      padding: 0,
      textAlign: "center"
    },
    statusItem: {
      color: "#5C5C5C",
      fontFamily: "Poppins",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      padding: "6px"
    },
    buttonBox: {
      marginTop: 14,
        display: 'flex',
      justifyContent: "right",
      gap: 10,
      alignItems: 'center',
    },
    cancelButton: {
      borderRadius: '12px',
      border: `1px solid ${theme.backGreyFilter}`,
      background: theme.backGreyFilter,
      color: theme.purple,
      fontFamily: 'Poppins',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '23.4px',
      textTransform: 'capitalize',
      padding: 0,
    },
    applyButton: {
      borderRadius: '12px',
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      color: '#FFF',
      fontFamily: 'Poppins',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '23.4px',
      textTransform: 'capitalize',
      padding: 0,
    },
}

export default FilterModal