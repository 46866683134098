import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, Paper, TableCell, TableContainer, TableHead, TableRow, Typography, Pagination, Modal, CircularProgress } from '@mui/material';
import DashboardSidebar from 'components/DashboardSidebar';
import PrintIcon from 'assets/icons/PrintIcon';
import EyeIcon from 'assets/icons/EyeIcon';
import styles from 'assets/styles/applicationPage';
import CrossIcon from 'assets/icons/CrossIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { useAppContext } from 'context/AppContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import validateToken from 'services/validateToken';
import { toast, ToastContainer } from 'react-toastify';
import FilterIcon from 'assets/icons/FilterIcon';
import FilterModal from 'components/FilterModal';
import SortIcon from 'assets/icons/SortIcon';
import AscendingIcon from 'assets/icons/AscendingIcon';
import DescendingIcon from 'assets/icons/DescendingIcon';

const Applications = () => {
  const token = localStorage.getItem('token');
  const { jobProfiles } = useAppContext();
  const [jobPosting, setJobPosting] = useState('');
  const [selected, setSelected] = useState([]);
  const [filteredApplication, setFilteredApplications] = useState([]);
  const [applications, setApplications] = useState([]);
  const [noticeSort, setNoticeSort] = useState('nosort');
  const [experienceSort, setExperienceSort] = useState('nosort');
  const [loading, setLoading] = useState(false);
  const jobListings = applications.reduce((acc, current) => {
  const profile = jobProfiles.find(profile => profile.job_id === current.job_id);
  const jobTitle = profile ? profile.job_title : current.job_title;

  const duplicate = acc.find(item => item.job_id === current.job_id && item.job_title === jobTitle);
  if (!duplicate) {
    acc.push({ ...current, job_title: jobTitle });
  }
  return acc;
}, []);
  const [showModal, setShowModal] = useState(false);
  const [viewApplication, setViewApplication] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [isDownloading, setIsDownloading] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const navigate = useNavigate();

  const [totalApplicants, setTotalApplicants] = useState(applications.length)
  const totalPages = Math.ceil(totalApplicants / itemsPerPage);
  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/getapplications`, {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApplications(response.data);
      setFilteredApplications(response.data);
    } catch (error) {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        fetchApplications()
      }
      console.error('Error fetching applications:', error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchApplications();
  }, [token]);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    const selectedJobId = event.target.value;
    if (selectedJobId === "Show All") {
      setFilteredApplications(applications)
      setJobPosting("Show All")
    }
    else {
      setJobPosting(selectedJobId);
      const filtered = applications.filter(app => app.job_id === selectedJobId);
      setFilteredApplications(filtered);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = applications.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((app) => app.applicant_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleModal = (application) => {
    setShowModal(true);
    setViewApplication(application);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleBulkChange = async (status) => {
    setLoading(true);
    const filteredSelected = selected.map(item => Number(item));
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/applications/bulk-action`, {
        applicant_ids: filteredSelected,
        status: status
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchApplications();
      console.log(response);
    } catch (error) {
        const isValidToken = await validateToken();
        if (!isValidToken) {
          navigate('/login');
          return;
        }
        else {
          handleBulkChange(status)
      }
      console.error('Error fetching applications:', error);
    }
    setLoading(false);
  }

const handleStatusChange = async (event, id) => {
  setLoading(true);
  
  try {
    // Await axios POST request to ensure proper error catching
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/applications/status/change`,
      {
        applicant_id: Number(id),
        status: event.target.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTimeout(async () => {
      await fetchApplications();
    }, 1000);
    
  } catch (error) {
    const isValidToken = await validateToken();
    if (!isValidToken) {
      navigate('/login');
      return;
    } else {
      handleStatusChange(event, id);
    }
    console.error('Error fetching applications:', error);
  } finally {
    setLoading(false);
  }
};


  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleExportCSV = () => {
    const csvHeader = '"Date Applied","Name","Experience","Notice Period","Status"\n';
    const csvData = filteredApplication
      .map(row => 
        `"${row.submit_date}","${row.name}","${row.experience}","${row.notice_period}","${row.status}"`
      )
      .join('\n');
    const csv = csvHeader + csvData;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'applications-data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const paginatedApplications = filteredApplication.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toDateString();
  };

  const setColor = (status) => {
    if (status === 'Hired') {
      return {color: "#009133"};
    }
    else if (status === 'Shortlisted') {
      return {color: "#CB903A"}
    }
    else if (status === "Rejected") {
      return {color: "#D40000"}
    }
  }

  function convertDaysToMonthsAndDays(days) {
    const months = Math.floor(days / 30); 
    const remainingDays = days % 30;

    let result = "";
    if (months > 0) {
        result += `${months} month${months > 1 ? 's' : ''}`;
    }
    if (remainingDays > 0) {
        result += `${months > 0 ? ' and ' : ''}${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
    }

    return result;
  }
const downloadAndOpenPdf = async (url) => {
  setIsDownloading(true);
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    const fileUrl = URL.createObjectURL(blob);

    if (contentType === 'application/pdf') {
      window.open(fileUrl, '_blank'); 
    } else if (contentType.includes('image')) {
      const imgWindow = window.open();
      imgWindow.document.write(`<img src='${fileUrl}' />`); 
    } else if (
      contentType === 'application/msword' || 
      contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'download.doc'; 

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1]; 
        }
      }

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName); 
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.error('Unsupported file type');
    }
  } catch (error) {
    console.error('Error downloading the file', error);
  }
  setIsDownloading(false);
};
  
  useEffect(() => {
    setTotalApplicants(filteredApplication.length)
  }, [filteredApplication])
  
  const handleNoticeSort = () => {
    setExperienceSort('nosort')
    if (noticeSort === 'nosort') {
      let newApplications = filteredApplication.sort((a, b) => b.notice_period - a.notice_period)
      setFilteredApplications(newApplications)
      setNoticeSort("sortup")
    }
    else if (noticeSort === 'sortup') {
      let newApplications = filteredApplication.sort((a, b) => a.notice_period - b.notice_period)
      setFilteredApplications(newApplications)
      setNoticeSort("sortdown")
    }
    else {
      let newApplications = filteredApplication.sort((a, b) => b.notice_period - a.notice_period)
      setFilteredApplications(newApplications)
      setNoticeSort("sortup")
    }
  }

  const handleExperienceSort = () => {
    setNoticeSort('nosort')
    if (experienceSort === 'nosort') {
      let newApplications = filteredApplication.sort((a, b) => b.experience - a.experience)
      setFilteredApplications(newApplications)
      setExperienceSort("sortup")
    }
    else if (experienceSort === 'sortup') {
      let newApplications = filteredApplication.sort((a, b) => a.experience - b.experience)
      setFilteredApplications(newApplications)
      setExperienceSort("sortdown")
    }
    else {
      let newApplications = filteredApplication.sort((a, b) => b.experience - a.experience)
      setFilteredApplications(newApplications)
      setExperienceSort("sortup")
    }
  }

  const formatExperience = (totalYears) => {
    const years = Math.floor(totalYears); 
    const months = Math.round((totalYears - years) * 12); 
    return `${years} ${years > 1 ? "Years": "Year"}${months>0 ? ` ${months} ${months > 1 ? `Months` : `Month`}` : ''}`
  }

  return (
    <Box style={styles.dashboard}>
      <DashboardSidebar page="Applications"/>
      <Box style={styles.applicationsData}>
        <ToastContainer/>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter by posting</InputLabel>
          <Select
            style={styles.filterPosting}
            value={jobPosting}
            label="Filter by posting"
            onChange={handleChange}
          >
            <MenuItem value="Show All">Show All Applications</MenuItem>
            {jobListings.map((item) => (
              <MenuItem value={item.job_id}>{item.job_title} - {item.job_id}</MenuItem>
            ))}
          </Select>
        </FormControl>    
        <Box style={styles.TableTop}>
          <Box style={{display: "flex", position: "relative", gap: 20, alignItems: "center"}}>
            <Typography style={styles.totalApplicants}>Found {totalApplicants} Applicants</Typography>
            <Box style={styles.filterBox} onClick={()=> setFilterModal(!filterModal)}>
              <FilterIcon />
              <Typography variant='h6' style={styles.filterText}>Filter</Typography>
            </Box>
            <FilterModal visible={filterModal} applications={applications} setFilteredApplications={setFilteredApplications} setVisible={setFilterModal} fetchApplications={fetchApplications} />
          </Box>
          {selected.length > 1 && (
            <Box style={styles.bulkBox}>
              <Box>
                <Typography style={styles.bulkTitle}>Bulk Action</Typography>
                <Typography style={styles.bulkSubTitle}>on selected applications</Typography>
              </Box>
              <Button style={styles.bulkButton} onClick={()=>handleBulkChange("New")}>New</Button>
              <Button style={styles.bulkButton} onClick={()=>handleBulkChange("Shortlisted")}>Shortlist</Button>
              <Button style={styles.bulkButton} onClick={()=>handleBulkChange("Rejected")}>Reject</Button>
              <Button style={styles.bulkButton} onClick={()=>handleBulkChange("Hired")}>Hire</Button>
            </Box>
          )}
          <Button style={styles.printButton} onClick={handleExportCSV}>
            <PrintIcon />
          </Button>
        </Box>
        <TableContainer style={styles.tableContainer} component={Paper}>
          <Table>
            <TableHead style={styles.tableHead}>
              <TableRow>
                <TableCell style={{...styles.tableCellStyle, ...styles.tableLeftPadding}} padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < paginatedApplications.length}
                    checked={paginatedApplications.length > 0 && selected.length === paginatedApplications.length}
                    onChange={handleSelectAllClick}
                    style={styles.checkbox}
                  />
                </TableCell>
                <TableCell>Date Applied</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>
                 <Box style={styles.alignItems}>
                  Experience
                    <Button style={{minWidth: 0}} onClick={handleExperienceSort}>
                      {experienceSort === 'nosort' && <SortIcon/>}
                      {experienceSort === 'sortup' && <AscendingIcon/>}
                      {experienceSort === 'sortdown' && <DescendingIcon/>}
                    </Button>
                  </Box> 
                </TableCell>
                <TableCell>
                  <Box style={styles.alignItems}>
                    Notice Period
                    <Button style={{minWidth: 0}} onClick={handleNoticeSort}>
                      {noticeSort === 'nosort' && <SortIcon/>}
                      {noticeSort === 'sortup' && <AscendingIcon/>}
                      {noticeSort === 'sortdown' && <DescendingIcon/>}
                    </Button>
                  </Box> 
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {!loading ? <TableBody>
              {paginatedApplications.map((application) => {
                const isItemSelected = isSelected(application.applicant_id);
                return (
                  <TableRow
                    key={application.applicant_id}
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox" style={{...styles.tableCellFont, ...styles.tableLeftPadding}}>
                      <Checkbox checked={isItemSelected} style={styles.checkbox} onClick={(event) => handleClick(event, application.applicant_id)}  />
                    </TableCell>
                    <TableCell style={{...styles.tableCellFont, ...setColor(application.status)}}>{formatDate(application.submit_date)}</TableCell>
                    <TableCell style={{...styles.tableCellFont, ...setColor(application.status)}}>{application.name}</TableCell>
                    <TableCell style={{ ...styles.tableCellFont, ...setColor(application.status) }}>{formatExperience(application.experience)}</TableCell>
                    <TableCell style={{ ...styles.tableCellFont, ...setColor(application.status) }}>{convertDaysToMonthsAndDays(application.notice_period)}</TableCell>
                    <TableCell style={{...styles.tableCellFont,...setColor(application.status), textAlign: 'right'}} onClick={() => handleModal(application)}>
                      <EyeIcon />
                    </TableCell>
                    <TableCell>
                      <Select style={styles.setStatus} value={application.status} onChange={(event) => handleStatusChange(event, application.applicant_id)}>
                        <MenuItem style={styles.statusItem} value="New">New</MenuItem>
                        <MenuItem style={styles.statusItem} value="Shortlisted">Shortlisted</MenuItem>
                        <MenuItem style={styles.statusItem} value="Rejected">Rejected</MenuItem>
                        <MenuItem style={styles.statusItem} value="Hired">Hired</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>: 
             <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: "100px" }}>
                <CircularProgress style={{position:"absolute", left: "50%"}} />
            </Box>
              }
          </Table>
        </TableContainer>
        <Box style={styles.pagination}>
          <Typography style={{...styles.totalApplicants, fontWeight: 500, color: "#3E4954"}}>Showing {page===totalPages ? totalApplicants%itemsPerPage : itemsPerPage} from {totalApplicants}</Typography>
          <Pagination 
            count={totalPages} 
            page={page}
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </Box>
        <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Box style={{cursor: "pointer"}} onClick={() => setShowModal(false)}>
              <CrossIcon width={15} height={15} />
            </Box>
          </Box>
          <Box style={styles.viewApplicationDetails}>
            <Typography style={styles.modalTitle}>Application Details for {viewApplication?.job_title || 'Not Available'}</Typography>
            <Box>
              <Typography style={styles.viewApplicationText}>Name<span style={styles.textDetails}>{viewApplication?.name}</span></Typography>
              <Typography style={styles.viewApplicationText}>Current Designation<span style={styles.textDetails}>{viewApplication?.current_designation || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Salary Expectation<span style={styles.textDetails}>{viewApplication?.expected_salary || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Contact Number<span style={styles.textDetails}>{viewApplication?.contact_number || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Email<span style={styles.textDetails}>{viewApplication?.email || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Link to portfolio/ Linkedin<span style={styles.textDetails}>{viewApplication?.portfolio_link || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Summary/ Cover Letter<span style={styles.textDetails}>{viewApplication?.summary || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Resume</Typography>
              <Box style={styles.modalBtnContainer}>
                <Button 
                  style={styles.modalButton} 
                  onClick={() => downloadAndOpenPdf(`${process.env.REACT_APP_BASE_URL}/api/dashboard/resume/${viewApplication.applicant_id}`)}
                >
                  {isDownloading ? <CircularProgress size={24} /> : 'View'}<EyeIcon color="#fff"/>
                </Button>
                <Button 
                  style={styles.modalButton} 
                  onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/api/dashboard/resume/${viewApplication.applicant_id}`, '_blank')}
                >
                  Download<DownloadIcon/>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      </Box>
    </Box>
  );
};

export default Applications;
