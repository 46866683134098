import React from 'react'

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none">
    <path d="M20.2183 5.16508L11.3609 14.0211C10.4788 14.903 7.86058 15.3115 7.27566 14.7267C6.69074 14.1418 7.08997 11.524 7.972 10.6421L16.8387 1.77671C17.0574 1.53819 17.3221 1.34646 17.617 1.21306C17.9118 1.07966 18.2306 1.00734 18.5542 1.00053C18.8776 0.993725 19.1992 1.05252 19.4994 1.1734C19.7996 1.29428 20.0722 1.47474 20.3007 1.70385C20.5292 1.93296 20.7089 2.20597 20.829 2.50643C20.949 2.8069 21.007 3.12856 20.9993 3.45203C20.9916 3.7755 20.9184 4.09409 20.7842 4.3885C20.6499 4.68291 20.4575 4.9471 20.2183 5.16508Z" stroke="#6D0185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 3H5C3.93913 3 2.92178 3.42142 2.17163 4.17157C1.42149 4.92172 1 5.93913 1 7V17C1 18.0609 1.42149 19.0783 2.17163 19.8284C2.92178 20.5786 3.93913 21 5 21H16C18.21 21 19 19.2 19 17V12" stroke="#6D0185" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default EditIcon