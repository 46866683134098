import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [jobProfiles, setJobProfiles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const fetchJobProfiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/getjobs`);
        setJobProfiles(response.data);
      } catch (error) {
        console.error('Error fetching job profiles:', error);
      }
    };

    fetchJobProfiles();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/departments`);
        setDepartments(response.data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, []);

    const fetchSeniorityLevels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/seniority-levels`);
        setSeniorityLevels(response.data);
      } catch (error) {
        console.error('Error fetching seniority levels:', error);
      }
    };

  useEffect(() => {

    fetchSeniorityLevels();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/locations`);
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  const refreshDepartments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/departments`);
      setDepartments(response.data);
    } catch (error) {
      console.error('Error refreshing departments:', error);
    }
  };

  const refreshSeniorityLevels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/seniority-levels`);
      setSeniorityLevels(response.data);
    } catch (error) {
      console.error('Error refreshing seniority levels:', error);
    }
  };

  const refreshLocations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/locations`);
      setLocations(response.data);
    } catch (error) {
      console.error('Error refreshing locations:', error);
    }
  };

  const updateJobProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/getjobs`);
      setJobProfiles(response.data);
    } catch (error) {
      console.error('Error updating job profiles:', error);
    }
  };

  const contextValue = {
    jobProfiles,
    updateJobProfile,
    authToken,
    setAuthToken,
    departments,
    refreshDepartments,
    seniorityLevels,
    refreshSeniorityLevels,
    locations,
    refreshLocations,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
