import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import JobDetailsBox from 'components/JobDetailBox';
import JobItem from 'components/JobItem';
import JobSearchBox from 'components/JobSearchBox';
import JobDetailsTabs from 'components/JobDetailsTabs';
import styles from 'assets/styles/HomePage';
import { useAppContext } from 'context/AppContext';
import { useLocation, useParams } from 'react-router-dom';

const Home = () => {
  const { jobProfiles } = useAppContext();
  const location = useLocation(); 

  const [jobOpennings, setJobOpennings] = useState(jobProfiles);
  useEffect(()=> {
    setJobOpennings(jobProfiles)
  },[jobProfiles])
  const [tabValue, setTabValue] = useState(0);
  const [activeJobIndex, setActiveJobIndex] = useState(0);
    const getJobIdFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  };

  useEffect(() => {
    const jobIdFromQuery = getJobIdFromQuery();
    if (jobIdFromQuery) {
      const index = jobOpennings.findIndex(job => Number(job.job_id) === Number(jobIdFromQuery));
      if (index !== -1) {
        setActiveJobIndex(index);
      } else {
        setActiveJobIndex(0);
      }
    } else {
      setActiveJobIndex(0);
    }
  }, [location.search, jobOpennings]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleJobItemClick = (index) => {
    setActiveJobIndex(index);
  };

  return (
    <Box>
      <JobSearchBox totalJobs={jobOpennings.length} jobOpennings={jobProfiles} setJobOpennings={setJobOpennings} />
      <Box sx={styles.mainContainer}>
        <Box sx={styles.jobItemContainer}>
          <Typography style={styles.totalResultText}>
            Showing {jobOpennings.length} results
          </Typography>
          {jobOpennings.length> 0 ? jobOpennings.map((job, index) => (
            <JobItem 
              key={job.id} 
              active={index === activeJobIndex} 
              data={job} 
              onClick={() => handleJobItemClick(index)} 
            />
          ))
        : null}
        </Box>
        {jobOpennings.length> 0 ? 
        <Box sx={{ ...styles.flexContainer, ...styles.contentContainer }}>
           <JobDetailsBox data={jobOpennings[activeJobIndex]} />
           <JobDetailsTabs data={jobOpennings[activeJobIndex]} tabValue={tabValue} handleTabChange={handleTabChange} />
        </Box> : null }
      </Box>  
    </Box>
  );
};

export default Home;
