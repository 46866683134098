import axios from "axios";

const validateToken = async () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!token) {
    return false; 
  }
  
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        }
      });
    localStorage.setItem('token', response.data.access_token);
    return true; 
  } catch (error) {
    try { 
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/validate-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Origin: 'https://fanisko.com',
        },
      }
    );
      return true;  
    }
    catch (error) {
    console.error('Token validation failed:', error);
    return false; 
    }
  }
};

export default validateToken;
