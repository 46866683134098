import theme from "theme";

const styles = {
    tab: {
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 275,
      lineHeight: '23.4px',
    },
    activeTab: {
      color: `${theme.purple} !important`,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '23.4px',
    },
    summaryTitle: {
      marginTop: '18px',
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23.4px',
    },
    summaryList: {
      marginTop: '26px',
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
    },
    additionalContainer: {
      display: 'flex',
      gap: '30px',
      marginTop: '20px',
    },
    childContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      borderRadius: '8px',
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      color: '#202124',
    },
    subTitle: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#202124', 
    },
    imageContainer: {
      position: "relative",
      width: "50px",
      height: "50px",
      boxSizing: "border-box",
      borderRadius: "25px",
      background: "rgba(25, 103, 210, 0.07)"
    },
    image: {
      position: 'absolute',
      top: '50%',
      left: '25px',
      transform: 'translate(-50%, -50%)'
    },
  };

  export default styles;