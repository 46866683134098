// src/components/DesktopOnly.js
import styles from 'assets/styles/desktopOnly';
import React, { useState, useEffect } from 'react';

const DesktopOnly = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  if (!isDesktop) {
    return (
      <div style={styles.container}>
        <div style={styles.icon}>🖥️</div>
        <div style={styles.heading}>Desktop Only</div>
        <div style={styles.message}>This app is for desktop only. Please use a larger screen.</div>
      </div>
    );
  }

  return children;
};

export default DesktopOnly;
