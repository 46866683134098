import { Box } from '@mui/material'
import CustomBarChart from 'BarChart'
import DashboardSidebar from 'components/DashboardSidebar'
import RecentApplications from 'components/RecentApplications'
import RecentJobs from 'components/RecentJobs'
import React from 'react'
import theme from 'theme'

const Dashboard = () => {
  const styles = {
    dashboard: {
      display: "flex",
      gap: 88,
    },
    dashboardData: {
      display: "grid",
      gridTemplateColumns: "auto auto", 
      columnGap: "22px",
      rowGap: "73px",
      paddingTop: "44px",
      paddingRight: "52px",
      paddingBottom: "100px",
    },
    barChart: {
      borderRadius: '16px',
      backgroundColor: '#FFF',
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.25)',
      padding: '15px 15px 15px 0',
      minWidth: "501px",
      height: 357
    },
    title: {
      paddingLeft: 30,
      color: theme.Bodytext,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
    },
  }
  return (
    <Box style={styles.dashboard}>
      <DashboardSidebar page="Dashboard"/>
      <Box style={styles.dashboardData}>
        <Box style={styles.barChart}>
          <CustomBarChart/>
        </Box>
        <RecentApplications />
        <Box></Box>
        <RecentJobs/>
      </Box>
    </Box>
  )
}

export default Dashboard