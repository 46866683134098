import theme from "theme";

const styles = {
    box: {
      borderRadius: '16px',
      backgroundColor: '#FFF',
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.25)',
      padding: '30px',
      maxWidth: "501px",
      overflowX: "auto",
    },
    title: {
      color: theme.Bodytext,
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
    },
    list: {
      maxHeight: '284px',
      minWidth: "491px",
      overflowY: 'auto',
      paddingRight: '10px',
    },
    listItem: {
      borderRadius: "16px",
      border: `1px solid ${theme.purple}`,
      display: "flex",
      alignItems: "center",
      gap: 12,
      justifyContent: "space-between",
      marginBottom: 10,
    },
    scrollbar: {
      width: '10px',
    },
    scrollbarTrack: {
      borderRadius: '4px',
      background: '#F2F2F2',
    },
    scrollbarThumb: {
      borderRadius: '4px',
      background: theme.purple,
    },
    applicationBtn: {
      borderRadius: "20px",
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: "23.4px",
      textTransform: "capitalize",
      padding: "4px 10px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
      justifyContent: "right"
    },
    details: {
      display: "flex",
      color: "#000",
      fontFamily: "Poppins",
      alignItems: "center",
      gap: "10px",
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23.4px",
      margin: 0,
      padding: 0,
    },
    applicantDetails: {
      display: "flex",
      gap: "10px",
      alignItems: "center"
    },
    portfolio:{
      display: "flex",
      gap: "10px",
      alignItems: "center",
      margin: 0,
    },
    resume: {
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
    applicantDetailsText: {
      color: theme.purple,
      fontFamily: "Poppins",
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23.4px"
    },
    ApplicantName: {
      fontFamily: "Poppins",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
    },
    titleText: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
    },
    jobDetails: {
      display: "flex",
      gap: 9,
      marginTop: 14,
    },
    detailBox: {
      display: "flex",
      gap: 5,
      borderRadius: "37.607px",
      padding: "3.009px 23.171px 1.316px 9.026px",
      alignItems: "center",
      border: "0.752px solid #D1D0D0",
      color: "#202124",
      fontFamily: "Poppins",
      fontSize: "8px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21.06px"
    },
    iconText: {
      display: "flex",
      gap: 12,
      alignItems :"center"
    },
    addModal: {
      outline: "none",
      borderRadius: "33px",
      background: "#FFF",
      padding: "25px 25px 80px 0",
      width: "calc(100% - 32px)",
      maxWidth: "670px",
      maxHeight: "90vh",
      overflowY: "auto",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"
    },
    modalHead: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      marginBottom: 20
    },
    modalTitle:{
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      color: theme.purple,
      marginBottom: 22
    },
    viewApplicationText: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      lineHeight: "28px",
      fontWeight: 500,
      color: theme.purple,
      marginBottom: 10
    },
    textDetails: {
      color: "#000"
    },
    viewApplicationDetails: {
      maxWidth: 524,
      position: "relative",
      margin: "0 auto"
    },
    modalBtnContainer: {
      display: "flex",
      gap: 10
    },
    modalButton: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: "28px",
      fontWeight: 400,
      color: "#fff",
      textTransform: "capitalize",
      borderRadius: "12px",
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      display: "flex",
      gap: 8.5,
    }
  };

  export default styles;