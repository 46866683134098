import React from 'react'

const AscendingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2.66669H10.6667M2 5.33335H8M2 8.00002H6M8.66667 8.00002L11.3333 5.33335M11.3333 5.33335L14 8.00002M11.3333 5.33335V13.3334" stroke="#111827" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AscendingIcon