import theme from "theme";

const styles = {
    container: {
      padding: '50px 60px',
      background: theme.backGreyFilter,
    },
    title: {
      color: '#202124',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '36px',
      fontWeight: 500,
      lineHeight: '55px',
    },
    jobCount: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '36px',
      fontWeight: 500,
      lineHeight: '55px',
      color: theme.purple,
    },
    subtitle: {
      color: '#202124',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '26.25px',
      marginTop: '30px',
    },
    searchContainer: {
      marginTop: '35px',
      borderRadius: '8px',
      border: '1px solid #ECEDF2',
      background: '#FFF',
      boxShadow: '0px 6px 15px 0px rgba(64, 79, 104, 0.05)',
      padding: '20px 15px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      flex: 1,
      paddingRight: '16px',
      borderRight: '1px solid #ECEDF2',
    },
    input: {
      color: '#696969',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '28px',
      flex: 1,
      border: 'none',
      outline: 'none',
    },
    selectContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: '0 16px',
      borderRight: '1px solid #ECEDF2',
    },
    dropdown: {
      flex: 1,
      outline: 'none',
      border: 'none',
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    searchButtonContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchButton: {
      borderRadius: '8px',
      border: '1px solid #1967D2',
      background: theme.purple,
      padding: '15px 62.13px',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '28px',
      color: '#fff',
      marginLeft: '16px',
    },
    selectOption: {
      flex: 1,
      maxWidth: '346px',
      background: "#fff",
      border: 'none',
      outline: 'none',
      '& fieldset': {
        border: "none",
        outline: 'none',
      }
    },
    filterSelect: {
      flex: 1,
      maxWidth: '346px',
      background: "#fff",
      color: "#202124 !important",
      fontFamily: "Poppins",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      outline: "none",
      borderRadius: "8px",
      '& fieldset': {
        border: "none",
        outline: 'none',
      }
    },
    filterBox: {
      display: 'flex',
      alignItems: "flex-start",
      gap: "20px"
    },
    autocompleteContainer: {
      maxHeight: '60px',

      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '10px',
    },
  };

  export default styles;