import { Box, Button, Typography } from '@mui/material';
import TickIcon from 'assets/icons/TickIcon';
import styles from 'assets/styles/jobSubmitModal';
import JobItem from 'components/JobItem';
import { useAppContext } from 'context/AppContext';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const JobSubmitModal = ({visible}) => {
  const { id } = useParams();
  const { jobProfiles } = useAppContext();
  const [profile, setProfile] = useState()
  const navigate = useNavigate();
  useEffect(()=> {
    const data = jobProfiles.find(profile => profile.job_id === id);
    setProfile(data)
  },[jobProfiles])
  const handleDismiss = () => {
    navigate(-1)
  };

  return (
    <Box sx={{...styles.overlayContainer,display: visible ? "unset": "none",}}>
      <Box sx={styles.container}>
        <TickIcon />
        <Typography style={styles.thankyouText}>Thank You!</Typography>
        <Typography style={{ ...styles.para, marginTop: "30px", marginBottom: "36px" }}>Thank you for applying.</Typography>
        <Box sx={{textAlign: "left"}}>
            <JobItem
            active={true}
            data={profile}
            />
        </Box>
        <Typography style={{ ...styles.para, marginTop: "20px" }}>Your application has been successfully<br/> submitted.</Typography>
        <Button style={styles.dismissBtn} onClick={handleDismiss}>Dismiss</Button>
      </Box>
    </Box>
  );
};

export default JobSubmitModal;
