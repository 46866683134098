import theme from 'theme'
const styles = {
    mainContainer: {
      marginTop: '53px',
      marginBottom: '103px',
      padding: '0 50px',
      display: 'flex',
      gap: '5px',
    },
    jobItemContainer: {
      width: '400px',
      minWidth: '400px',
      maxHeight: "calc(100vh - 47px)",
      overflow: "auto",
      paddingRight: "30px",
    },
    flexContainer: {
      flexGrow: 1,
    },
    contentContainer: {
      borderRadius: '18px',
      border: '1px solid #ECEDF2',
      padding: '31px',
      '& .MuiTabs-indicator': {
        background: theme.purple,
      },
      maxHeight: "calc(100vh - 47px)",
      overflow: "auto"
    },
    totalResultText: {
      color: "#202124",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "26.25px",
      marginTop: "-5px",
      marginBottom: "12px",
    }
  };

  export default styles;