import theme from "theme";

const styles = {
    container: {
      padding: '15px 55px 16px 55px',
      background: theme.purple, 
      color: '#FFF',
      textAlign: 'left',
      position: 'fixed',
      zIndex: 111,
      left: 0,
      bottom: 0,
      width: '100%',
    },
    text: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "23.4px"
    }
  };

  export default styles