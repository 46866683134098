import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Button, Modal } from '@mui/material';
import axios from 'axios';
import LockIcon from 'assets/icons/LockIcon';
import GlobalIcon from 'assets/icons/GlobalIcon';
import LocationFilledIcon from 'assets/icons/LocationFilledIcon';
import styles from 'assets/styles/recentJobs';
import { useNavigate } from 'react-router-dom';
import CrossIcon from 'assets/icons/CrossIcon';
import EyeIcon from 'assets/icons/EyeIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import OnsiteIcon from 'assets/icons/OnsiteIcon';
import HybridIcon from 'assets/icons/HybridIcon';

const RecentJobs = () => {
  const [applications, setApplications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewApplication, setViewApplication] = useState([]);
  const navigate = useNavigate()

  const handleViewApplication = (app) => {
    setViewApplication(app)
    setShowModal(true)
  }

  useEffect(() => {
    const fetchRecentJobs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/recent-jobs`);
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching recent jobs:', error);
      }
    };

    fetchRecentJobs();
  }, []);

  const handleEdit = (application) => {
    navigate('/dashboard/job-opennings/edit-job', {state: {application}})
   }

  return (
    <Box style={styles.box}>
      <Typography style={styles.title}>Most Recent Job Listing</Typography>
      <List style={styles.list}>
        {applications.map((app, index) => (
          <ListItem key={index} button style={styles.listItem}>
            <Box style={styles.iconText}>
              <LockIcon width={30} height={23} />
              <Box>
                <Box>
                  <Typography style={styles.titleText}>{app.job_title}</Typography>
                </Box>
                <Box style={styles.jobDetails}>
                  <Typography style={styles.detailBox}>
                    <LocationFilledIcon width={13.5} height={13.5} />
                    {app.location}
                  </Typography>
                  <Typography style={styles.detailBox}>
                    {app?.job_type === 'Remote' &&
                      <GlobalIcon width={13} height={13} />
                    }
                    {app?.job_type === 'Onsite' &&
                      <OnsiteIcon width={13} height={13} />
                    }
                    {app?.job_type === 'Hybrid' &&
                      <HybridIcon width={13} height={13} />
                    }
                    {app.job_type}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={styles.buttonContainer}>
              <Button style={styles.applicationBtn} onClick={()=> handleViewApplication(app)}>
                View
              </Button>
              <Button style={styles.applicationBtn} onClick={()=> handleEdit(app)}>
                Edit
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Box style={{ cursor: "pointer" }} onClick={() => setShowModal(false)}>
              <CrossIcon width={15} height={15} />
            </Box>
          </Box>
          <Box style={styles.viewApplicationDetails}>
            <Typography style={styles.modalTitle}>
              Job Details for {viewApplication?.job_title || 'Not Available'}
            </Typography>
            <Box>
              <Typography style={styles.viewApplicationText}>Job Title<span style={styles.textDetails}>{viewApplication?.job_title || 'Not Available'}</span></Typography>
              <Typography style={styles.viewApplicationText}>Job Type<span style={styles.textDetails}>{viewApplication?.job_type || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Location<span style={styles.textDetails}>{viewApplication?.location || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Job Department<span style={styles.textDetails}>{viewApplication?.department_name || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Seniority Level<span style={styles.textDetails}>{viewApplication?.seniority_level || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>No of Positions<span style={styles.textDetails}>{viewApplication?.no_of_positions || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>No of Applications<span style={styles.textDetails}>{viewApplication?.no_of_applications || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Summary<span style={styles.textDetails}>{viewApplication?.summary || "Not Available"}</span></Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RecentJobs;
