import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bigLogo from 'assets/images/bigLogo.png';
import styles from 'assets/styles/login';
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/validate-token`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Origin: 'https://fanisko.com',
              }
            }
          );
          if (response.data.status === 'success') {
            navigate('/dashboard');
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error('Token validation failed:', error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    validateToken();
  }, [navigate]);

  const handleLogin = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, {
        email,
        password,
      },
      {
        headers: {
          Origin: 'https://fanisko.com',
        }
      });
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        navigate('/dashboard');
      } else {
        toast.error('Login failed: ' + response.data.msg);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return(
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 81px - 54px)"
      textAlign="center"
    >
      <CircularProgress size={60} thickness={4.5} />
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Please wait, data is being loaded...
      </Typography>
    </Box>)
  }

  return (
    <Box sx={styles.mainContainer}>
      <ToastContainer />
      <Box sx={styles.loginContainer}>
        <img width="258.108px" height="88.026px" style={{ marginBottom: 60 }} src={bigLogo} alt="Logo" />
        <TextField
          label="Email"
          variant="outlined"
          placeholder="employee@example.com"
          sx={styles.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          sx={styles.inputField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box style={styles.forgetPassword}>
          <Box style={styles.remember}>
            <Typography style={styles.label}>Remember me</Typography>
            <input type="checkbox" name="remember-me" />
          </Box>
          <Typography style={styles.label}>I’ve forgotten my password!</Typography>
        </Box>
        <Button
          variant="contained"
          sx={styles.loginButton}
          onClick={handleLogin}
          disabled={isSubmitting}
        >
          {isSubmitting ? <><CircularProgress size={24} /> Logging In.. </> : 'Login'}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
