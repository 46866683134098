

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    purple: "#6D0185",
    backGreyFilter: "#E4E4E4",
    lightPink: "#FDE8FF",
    primary: {
      main: '#6D0185',
    },
    secondary: {
      main: '#dc004e', 
    },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif', 
    body1: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
    }
  },
});

export default theme;
