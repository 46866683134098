const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      fontSize: '1.5em',
      color: '#fff',
      background: "rgba(136, 136, 136, 0.79)",
      padding: '20px',
      flexDirection: 'column',
    },
    heading: {
      fontFamily: "Poppins",
      fontSize: '2em',
      marginBottom: '20px',
    },
    message: {
        fontFamily: "Poppins",
      fontSize: '1.2em',
    },
    icon: {
      fontSize: '3em',
      marginBottom: '20px',
    },
  };

  export default styles;