import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from 'theme';
import LocationFilledIcon from 'assets/icons/LocationFilledIcon';
import GlobalIcon from 'assets/icons/GlobalIcon';
import styles from 'assets/styles/jobItem';
import HybridIcon from 'assets/icons/HybridIcon';
import OnsiteIcon from 'assets/icons/OnsiteIcon';

const JobItem = ({ active = false, data, onClick }) => {
  const containerStyles = {
    ...styles.container,
    borderColor: active ? theme.purple : "#000",
    backgroundColor: active ? "#FDE8FF" : "#FFF"
  };

  const typographyStyles = {
    ...styles.typography,
    color: active ? theme.purple : "#6E6E6E"
  };

    const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toDateString();
  };

  return (
    <Box sx={containerStyles} onClick={onClick}>
      <Typography sx={typographyStyles}>
        {data?.job_title}
      </Typography>
      <Box sx={styles.row}>
        <Box sx={styles.jobType}>
          {data?.job_status || 'Full-time'}
        </Box>
        <Box sx={{...styles.textContainer, borderRadius: "50px", border: active ? "none" : "1px solid #D1D0D0"}}>
          <LocationFilledIcon />
          <Typography sx={styles.text}>
            {data?.location}
          </Typography> 
        </Box>
        <Box sx={{...styles.textContainer, borderRadius: "50px", border: active ? "none" : "1px solid #D1D0D0"}}>
          {data?.job_type === 'Remote' &&
            <GlobalIcon />
          }
          {data?.job_type === 'Onsite' &&
            <OnsiteIcon />
          }
           {data?.job_type === 'Hybrid' &&
            <HybridIcon />
          }
          <Typography sx={{...styles.text, textTransform: "capitalize"}}>
            {data?.job_type}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{...styles.footerTypography, color : active ? theme.purple : "#868686"}}>
        Posted Date {formatDate(data?.created_on).slice(4,) || 'Not Available'}
      </Typography>
    </Box>
  );
};

export default JobItem;
