import React from 'react'

const HybridIcon = ({width=30, height=27}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 27" fill="none">
  <path d="M1 3.89664L5.15728 1L9.31456 3.89664" stroke="#6D0185" stroke-width="0.979589" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.39071 5.73999V9.11062C8.39071 9.28516 8.26664 9.42662 8.11356 9.42662H2.20098C2.04791 9.42662 1.92383 9.28516 1.92383 9.11062V5.73999" stroke="#6D0185" stroke-width="0.979589" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.4872 15.9194V12.2097C11.4872 11.8088 11.8235 11.4839 12.2384 11.4839H17.3468C17.7617 11.4839 18.098 11.8088 18.098 12.2097V15.9194M11.4872 15.9194H18.098M11.4872 15.9194L10.9312 17.8186C10.7955 18.2818 11.1557 18.742 11.654 18.742H17.9311C18.4294 18.742 18.7896 18.2818 18.654 17.8186L18.098 15.9194" stroke="#6D0185"/>
  <path d="M14.417 17.6531H15.1682" stroke="#6D0185" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.5708 25.1262H29.2334" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27.3179 16.3899H23.4866C22.3372 16.3899 21.9541 17.1718 21.9541 18.1372V25.1265H28.8504V18.1372C28.8504 17.1718 28.4673 16.3899 27.3179 16.3899Z" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.4868 22.7239H24.6362" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.1685 22.7239H27.3178" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.4868 20.7581H24.6362" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.1685 20.7581H27.3178" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.4868 18.7922H24.6362" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.1685 18.7922H27.3178" stroke="#6D0185" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.3194 2.61279L15.8535 5.33804L19.3194 8.06329" stroke="#6D0185" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.8535 5.33789H21.795C23.9825 5.33789 25.756 6.73236 25.756 8.45246V13.903" stroke="#6D0185" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.97559 26.0001L11.3658 23.2748L8.97559 20.5496" stroke="#6D0185" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.3659 23.2748H7.26833C5.75965 23.2748 4.53662 21.8803 4.53662 20.1602V14.7097" stroke="#6D0185" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default HybridIcon