import React from 'react'

const DashboardIcon = ({active=false}) => {
  console.log(active)
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.331" height="19.469" viewBox="0 0 25 20" fill="none">
    <path d="M22.3038 6.91133V3.22853C22.3038 2.08475 21.655 1.62238 20.043 1.62238H15.9473C14.3354 1.62238 13.6865 2.08475 13.6865 3.22853V6.90322C13.6865 8.05511 14.3354 8.50937 15.9473 8.50937H20.043C21.655 8.51749 22.3038 8.05511 22.3038 6.91133Z" fill={active? "#FDE8FF" : "#646464"}/>
    <path d="M22.3038 16.0372V12.76C22.3038 11.4702 21.655 10.951 20.043 10.951H15.9473C14.3354 10.951 13.6865 11.4702 13.6865 12.76V16.0372C13.6865 17.327 14.3354 17.8462 15.9473 17.8462H20.043C21.655 17.8462 22.3038 17.327 22.3038 16.0372Z" fill={active? "#FDE8FF" : "#646464"}/>
    <path d="M10.6446 6.91133V3.22853C10.6446 2.08475 9.9958 1.62238 8.38386 1.62238H4.28811C2.67617 1.62238 2.02734 2.08475 2.02734 3.22853V6.90322C2.02734 8.05511 2.67617 8.50937 4.28811 8.50937H8.38386C9.9958 8.51749 10.6446 8.05511 10.6446 6.91133Z" fill={active? "#FDE8FF" : "#646464"}/>
    <path d="M10.6446 16.0372V12.76C10.6446 11.4702 9.9958 10.951 8.38386 10.951H4.28811C2.67617 10.951 2.02734 11.4702 2.02734 12.76V16.0372C2.02734 17.327 2.67617 17.8462 4.28811 17.8462H8.38386C9.9958 17.8462 10.6446 17.327 10.6446 16.0372Z" fill={active? "#FDE8FF" : "#646464"}/>
    </svg>
  )
}

export default DashboardIcon