import React from 'react'

const SortIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.51105 11.4778L0.446043 8.96179C0.201109 8.66309 0.150144 8.24988 0.315183 7.90063C0.480223 7.55127 0.831663 7.32825 1.21802 7.32776H5.349C5.73535 7.32825 6.08679 7.55127 6.25183 7.90063C6.41687 8.24988 6.3659 8.66309 6.12097 8.96179L4.05597 11.4778C3.86603 11.7092 3.5824 11.8434 3.28296 11.8434C2.98352 11.8434 2.69989 11.7092 2.50995 11.4778H2.51105ZM1.16705 4.66882C0.778379 4.66895 0.424925 4.44373 0.260679 4.09155C0.0964335 3.73926 0.151182 3.32373 0.40106 3.026L2.50903 0.513916C2.69903 0.287476 2.97943 0.156738 3.27502 0.156738C3.57062 0.156738 3.85101 0.287476 4.04101 0.513916L6.15002 3.02588C6.3999 3.32373 6.45465 3.73926 6.2904 4.09155C6.12616 4.44373 5.7727 4.66895 5.38403 4.66895L1.16705 4.66882Z" fill="#646464"/>
    </svg>
  )
}

export default SortIcon