import theme from "theme";

const styles = {
    container: {
      borderRadius: '18px',
      border: `1px solid #000`,
      backgroundColor: '#FFF',
      padding: '30px 34px 10px 34px',
      cursor: 'pointer',
      marginBottom: "20px",
      maxWidth: "410px",
    },
    typography: {
      color: "#6E6E6E",
      fontFamily: 'Poppins',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '23.4px',
      marginBottom: '10px'
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: "10px",
      justifyContent: "space-between",
      marginTop: "35px",
      marginBottom: '10px'
    },
    jobType: {
      borderRadius: '50px',
      background: 'rgba(25, 103, 210, 0.15)',
      padding: '5px 15px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      color: theme.purple,
      whiteSpace: "nowrap"
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: "6px 12px"
    },
    jobMode: {
      justifyContent: "right"
    },
    text: {
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      whiteSpace: "nowrap",
      maxWidth: "65px",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    footerTypography: {
      color: "#868686",
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '28px'
    }
  };

  export default styles;