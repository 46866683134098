import React from 'react';
import { Button, Typography, Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import defaultAvatar from 'assets/images/Female.png';

const UserProfile = ({ name, role, image, styles }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); 
    navigate('/login'); 
  };

  return (
    <Box style={styles.userProfileBox}>
      <Avatar style={styles.userAvatar} src={image || defaultAvatar} alt={name}>
        {!image && name[0]}
      </Avatar>
      <Box>
        <Typography variant="body1" style={styles.userName}>
          {name}
        </Typography>
        <Typography variant="body1" style={styles.logoutBox}>
          {role} | {}
          <Button 
            style={styles.logoutBtn} 
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Typography>
      </Box>
    </Box>
  );
};

export default UserProfile;
