import theme from "theme";

const styles = {
    container: {
      borderRadius: '18px',
      border: `1px solid ${theme.purple}`,
      backgroundColor: '#FDE8FF',
      padding: '30px 34px 5px 34px',
      marginBottom: "35px"
    },
    typography: {
      color: "#000",
      fontFamily: 'Poppins',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '23.4px',
      marginBottom: '10px'
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: "5px"
    },
    jobTypes: {
      display: 'flex',
      alignItems: 'center',
      gap: "10px",
      marginTop: "35px",
      marginBottom: '31px'
    },
    jobType: {
      borderRadius: '50px',
      background: 'rgba(25, 103, 210, 0.15)',
      padding: '5px 15px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      color: theme.purple
    },
    locationType: {
      borderRadius: '50px',
      background: '#FFF',
      padding: '5px 15px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
    },
    applyButton: {
      display: "flex",
      gap: "5px",
      fontFamily: 'Poppins',
      fontSize: '14px',
      padding: "5px 15px",
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      color: "#fff",
      borderRadius: "12px",
      border: `1px solid ${theme.purple}`,
      background: theme.purple,
      cursor: 'pointer'
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    },
    text: {
      color: '#202124',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px'
    },
    footerTypography: {
      color: "#868686",
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '28px'
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems:"center"
    },
    link: {
      cursor: "pointer"
    }
  };

  export default styles;