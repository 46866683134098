import React, { useState, useEffect } from 'react';
import {
  Box, Button, Checkbox, FormControl, InputBase, Table, TableBody, Paper,
  TableCell, TableContainer, TableHead, TableRow, Typography, Pagination,
  Modal,
  CircularProgress
} from '@mui/material';
import DashboardSidebar from 'components/DashboardSidebar';
import PrintIcon from 'assets/icons/PrintIcon';
import EyeIcon from 'assets/icons/EyeIcon';
import EditIcon from 'assets/icons/EditIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import SearchIcon from 'assets/icons/SearchIcon';
import styles from 'assets/styles/applicationPage';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import axios from 'axios';
import CrossIcon from 'assets/icons/CrossIcon';
import { toast, ToastContainer } from 'react-toastify';
import validateToken from 'services/validateToken';

const JobOpenings = () => {
  const token = localStorage.getItem('token');
  const { jobProfiles, updateJobProfile } = useAppContext();
  const [jobPosting, setJobPosting] = useState('');
  const [selected, setSelected] = useState([]);
  const [applications, setApplications] = useState([]);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [viewApplication, setViewApplication] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    updateJobProfile()
  }, [loading])


  useEffect(() => {
    const totalApplicants = jobProfiles.length;
    setTotalApplicants(totalApplicants);

    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    let filteredJobProfiles = jobProfiles.sort((a, b) => {
      const dateA = new Date(a.created_on);
      const dateB = new Date(b.created_on);
      return dateB - dateA; 
    });
    setApplications(jobProfiles.slice(startIndex, endIndex));
  }, [page, jobProfiles]);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleBulkDelete = async () => {
    const filteredSelected = selected.map(item => Number(item));
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/bulk-delete`, {
        job_ids: filteredSelected,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowBulkDelete(false);
    } catch (error) {
      navigate('/login')
      console.error('Error fetching applications:', error);
    }
    setLoading(false);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = applications.map((app) => app.job_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleShowModal = (app) => {
    setViewApplication(app)
    setShowModal(true)
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleExportCSV = () => {
    const csvHeader = '"Job ID","Created On","Job Title","No of Positions","Target Date","No of Applications"\n';
    const csvData = applications
      .map(row => 
        `"${row.job_id}","${row.created_on}","${row.job_title}","${row.no_of_positions}","${row.target_date}","${row.no_of_applications}"`
      )
      .join('\n');
    const csv = csvHeader + csvData;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'job-openings.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/delete`, {
        job_id: Number(id),
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.status ? "Job deleted successfully" : response.data.status);
    } catch (error) {
      toast.error(error);
      const isValidToken = await validateToken();
      if (!isValidToken) {
        navigate('/login');
        return;
      }
      else {
        handleDelete(id)
      }
        console.error('Error fetching applications:', error);
    }
    setLoading(false);
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toDateString();
  };

  const handleFilterSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = jobProfiles.filter(job =>
      job?.job_id?.toLowerCase().includes(query) ||
      job?.job_title?.toLowerCase().includes(query)
    );
    setPage(1); 
    setTotalApplicants(filteredData.length);
    setApplications(filteredData.slice(0, 10));
  };

  const handleEdit = (application) => {
    navigate('/dashboard/job-opennings/edit-job', {state: {application}})
  }
  
    useEffect(() => {
    if (selected.length > 1)
      setShowBulkDelete(true);
      if (selected.length <= 1) {
      setShowBulkDelete(false);
    }
  }, [selected])

  return (
    <Box style={styles.dashboard}>
      <DashboardSidebar page="Job Openings"/>
      <Box style={styles.applicationsData}>
      <ToastContainer />
        <FormControl fullWidth>
          <Box style={styles.searchContainer}>
            <InputBase
              placeholder="Search any job ID/ title"
              style={styles.searchInput}
              onChange={handleFilterSearch}
              inputProps={{ 'aria-label': 'search' }}
            />
            <SearchIcon color={styles.searchIcon.color} />
          </Box>
        </FormControl>    
        <Box style={styles.TableTop}>
          <Typography style={styles.totalApplicants}>Found {totalApplicants} Job Openings</Typography>
          <Box style={styles.listingButtonContainer}>
            {showBulkDelete &&
              <Button style={styles.printButton} onClick={handleBulkDelete}>
                <DeleteIcon color="#fff" />
              </Button>
            }
            <Button style={{...styles.printButton, ...styles.addJobListingBtn}} onClick={() => navigate("add-new-job")}>
              Add Job Listing
            </Button>
            <Button style={styles.printButton} onClick={handleExportCSV}>
              <PrintIcon />
            </Button>
          </Box>
        </Box>
        <TableContainer style={styles.tableContainer} component={Paper}>
          <Table>
            <TableHead style={styles.tableHead}>
              <TableRow>
                <TableCell style={{...styles.tableLeftPadding}} padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < applications.length}
                    checked={applications.length > 0 && selected.length === applications.length}
                    onChange={handleSelectAllClick}
                    style={styles.checkbox}
                  />
                </TableCell>
                <TableCell style={{textAlign: "center"}}>Job ID</TableCell>
                <TableCell style={{textAlign: "center"}}>Created on</TableCell>
                <TableCell style={{textAlign: "center"}}>Job Title Name</TableCell>
                <TableCell style={{textAlign: "left"}}>No of<br/>positions</TableCell>
                <TableCell style={{textAlign: "center"}}>Target Date</TableCell>
                <TableCell style={{textAlign: "left"}}>No of<br/>applications</TableCell>
                <TableCell style={{textAlign: "center"}}>Action</TableCell>
              </TableRow>
            </TableHead>
            {!loading ? <TableBody style={{position: "relative"}}>
              {applications.map((application) => {
                const isItemSelected = isSelected(application.job_id);
                return (
                  <TableRow
                    key={application.job_id}
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    
                  >
                    <TableCell padding="checkbox" style={{...{...styles.tableCellFont, textAlign: "center"}, ...styles.tableLeftPadding}}>
                      <Checkbox checked={isItemSelected} style={styles.checkbox} onClick={(event) => handleClick(event, application.job_id)} />
                    </TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{application.job_id}</TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{formatDate(application.created_on)}</TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{application.job_title}</TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{application.no_of_positions}</TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{formatDate(application.target_date)}</TableCell>
                    <TableCell style={{...styles.tableCellFont, textAlign: "center"}}>{application.no_of_applications}</TableCell>
                    <TableCell>
                      <Button style={styles.actionButtons} onClick={()=> handleEdit(application)}>
                        <EditIcon />
                      </Button>
                      <Button style={styles.actionButtons} onClick={()=> handleShowModal(application)}>
                        <EyeIcon />
                      </Button>
                      <Button 
                        style={styles.actionButtons} 
                        onClick={() => handleDelete(application.job_id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody> :
             <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: "100px" }}>
                <CircularProgress style={{position:"absolute", left: "50%"}} />
              </Box>
            }
          </Table>
        </TableContainer>
        <Box style={styles.pagination}>
          <Typography style={{...styles.totalApplicants, fontWeight: 500, color: "#3E4954"}}>Showing {applications.length} from {totalApplicants}</Typography>
          <Pagination 
            count={Math.ceil(totalApplicants / 10)} 
            shape="rounded" 
            page={page} 
            color='secondary'
            onChange={handlePaginationChange} 
            siblingCount={0}
            boundaryCount={1}
          />
        </Box>
      </Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Box style={{ cursor: "pointer" }} onClick={() => setShowModal(false)}>
              <CrossIcon width={15} height={15} />
            </Box>
          </Box>
          <Box style={styles.viewApplicationDetails}>
            <Typography style={styles.modalTitle}>
              Job Details for {viewApplication?.job_title || 'Not Available'}
            </Typography>
            <Box>
              {console.log(viewApplication)}
              <Typography style={styles.viewApplicationText}>Job Title<span style={styles.textDetails}>{viewApplication?.job_title || 'Not Available'}</span></Typography>
              <Typography style={styles.viewApplicationText}>Job Type<span style={styles.textDetails}>{viewApplication?.job_type || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Location<span style={styles.textDetails}>{viewApplication?.location || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Job Department<span style={styles.textDetails}>{viewApplication?.department_name || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Seniority Level<span style={styles.textDetails}>{viewApplication?.seniority_level || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>No of Positions<span style={styles.textDetails}>{viewApplication?.no_of_positions || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>No of Applications<span style={styles.textDetails}>{viewApplication?.no_of_applications || "0"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Summary<span style={styles.textDetails}>{viewApplication?.summary || "Not Available"}</span></Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default JobOpenings;
