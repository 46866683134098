import React from 'react'

const DeleteIcon = ({color="#D40000"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 24 27" fill="none">
  <path d="M8 13V20" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 13V20" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 7H23" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 7H11.5H20V21.9286C20 24.1772 18.0973 26 15.75 26H7.25C4.9028 26 3 24.1772 3 21.9286V7Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 4C7 2.34314 8.34314 1 10 1H13C14.6569 1 16 2.34314 16 4V7H7V4Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default DeleteIcon