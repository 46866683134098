import theme from "theme";

const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px 43px 18px 63px',
      background: theme.backGreyFilter,
    },
    title: {
      color: theme.purple,
      fontFamily: 'Poppins, sans-serif',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '23.4px',
    },
    jobTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${theme.purple}`,
      borderRadius: '25px',
      padding: '27px 33px',
      gap: '24px',
      minWidth: "308px"
    },
    jobTitleText: {
      display: 'flex',
      flexDirection: 'column',
    },
    jobTitle: {
      color: '#202124',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '23.4px',
    },
    jobLocation: {
      marginTop: "5px",
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#202124',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  };

  export default styles;