import React from 'react'

const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M13.3 8.4C13.1143 8.4 12.9363 8.47375 12.805 8.60503C12.6737 8.7363 12.6 8.91435 12.6 9.1V11.9C12.6 12.0857 12.5263 12.2637 12.395 12.395C12.2637 12.5263 12.0857 12.6 11.9 12.6H2.1C1.91435 12.6 1.7363 12.5263 1.60503 12.395C1.47375 12.2637 1.4 12.0857 1.4 11.9V9.1C1.4 8.91435 1.32625 8.7363 1.19497 8.60503C1.0637 8.47375 0.885652 8.4 0.7 8.4C0.514348 8.4 0.336301 8.47375 0.205025 8.60503C0.0737498 8.7363 0 8.91435 0 9.1V11.9C0 12.457 0.221249 12.9911 0.615076 13.3849C1.0089 13.7788 1.54305 14 2.1 14H11.9C12.457 14 12.9911 13.7788 13.3849 13.3849C13.7788 12.9911 14 12.457 14 11.9V9.1C14 8.91435 13.9263 8.7363 13.795 8.60503C13.6637 8.47375 13.4857 8.4 13.3 8.4ZM6.503 9.597C6.56957 9.66073 6.64807 9.71068 6.734 9.744C6.81779 9.78103 6.90839 9.80016 7 9.80016C7.09161 9.80016 7.18221 9.78103 7.266 9.744C7.35193 9.71068 7.43043 9.66073 7.497 9.597L10.297 6.797C10.4288 6.66519 10.5029 6.48641 10.5029 6.3C10.5029 6.11359 10.4288 5.93481 10.297 5.803C10.1652 5.67119 9.98641 5.59714 9.8 5.59714C9.61359 5.59714 9.43481 5.67119 9.303 5.803L7.7 7.413V0.7C7.7 0.514348 7.62625 0.336301 7.49497 0.205025C7.3637 0.0737498 7.18565 0 7 0C6.81435 0 6.6363 0.0737498 6.50503 0.205025C6.37375 0.336301 6.3 0.514348 6.3 0.7V7.413L4.697 5.803C4.63173 5.73773 4.55425 5.68596 4.46897 5.65064C4.3837 5.61532 4.2923 5.59714 4.2 5.59714C4.1077 5.59714 4.0163 5.61532 3.93103 5.65064C3.84575 5.68596 3.76827 5.73773 3.703 5.803C3.63773 5.86827 3.58596 5.94575 3.55064 6.03102C3.51532 6.1163 3.49714 6.2077 3.49714 6.3C3.49714 6.3923 3.51532 6.4837 3.55064 6.56897C3.58596 6.65425 3.63773 6.73173 3.703 6.797L6.503 9.597Z" fill="white"/>
</svg>
  )
}

export default DownloadIcon