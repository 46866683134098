import React from 'react'

const CrossIcon = ({width= 11, height=10}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 10" fill="none">
  <path d="M1.70797 1.00987L1.70798 1.00987C1.87979 0.853808 2.18552 0.853808 2.35733 1.00987L2.35734 1.00987L5.39083 3.76515L5.89509 4.22316L6.39935 3.76515L9.4297 1.01271C9.42972 1.0127 9.42974 1.01268 9.42976 1.01266C9.60173 0.856555 9.90737 0.856661 10.0791 1.01265L10.0792 1.01271C10.1542 1.08085 10.1768 1.15286 10.1768 1.2104C10.1768 1.26794 10.1542 1.33995 10.0792 1.40809L10.0791 1.40814L7.0487 4.1606L6.43745 4.71579L7.04872 5.27097L10.0779 8.02218C10.0779 8.0222 10.0779 8.02223 10.078 8.02225C10.1529 8.09042 10.1755 8.16245 10.1755 8.22001C10.1755 8.27759 10.1529 8.34958 10.0779 8.41769C9.90603 8.57384 9.60038 8.57384 9.42847 8.41769L6.39935 5.66641L5.89508 5.2084L5.39082 5.66642L2.36075 8.41864C2.36075 8.41865 2.36074 8.41865 2.36073 8.41866C2.18893 8.57467 1.88325 8.57469 1.71143 8.4187C1.63637 8.35052 1.61375 8.27846 1.61375 8.22087C1.61375 8.1633 1.63636 8.09127 1.71136 8.0231C1.71138 8.02308 1.7114 8.02306 1.71143 8.02304L4.74147 5.27096L5.35272 4.71578L4.74147 4.1606L1.70797 1.40534C1.63297 1.33721 1.61035 1.26519 1.61035 1.2076C1.61035 1.15002 1.63297 1.07799 1.70797 1.00987Z" stroke="#6D0185" stroke-width="1.5"/>
</svg>
  )
}

export default CrossIcon