import theme from "theme";

const styles = {
    sidebar: {
      // minWidth: '256px',
      maxWidth: "256px",
      padding: "0 25px",
      minHeight: "100vh",
      backgroundColor: theme.lightPink,
    },
    list: {
      position: "relative",
      // width: "200px",
      paddingTop: "52px",
      margin: "0 auto"
    },
    listItem: {
      display: "flex",
      marginBottom: "12px",
      borderRadius: "8px",
    },
    listItemIcon: {
      minWidth: "25px"
    },
    listItemText: {
      color: "#888",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      whiteSpace: "nowrap"
    },
    activeListItem: {
      borderRadius: "8px",
      background: theme.purple,
    },
    activeListItemText: {
      color: theme.lightPink
    }
  };

  export default styles