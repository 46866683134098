import React from 'react'

const ApplicationIcon = ({active=false}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path d="M16.99 21.5047H4.64588C4.41957 21.5047 4.23441 21.3313 4.23441 21.1192V4.7348H3V22.4685H16.99V21.5047ZM12.9905 7.6069C12.4144 7.88447 11.6902 7.88447 11.1142 7.6069C10.1925 7.95771 9.58352 8.79814 9.58352 9.74651H14.5212C14.5335 8.82898 13.8998 7.94229 12.9905 7.6069Z" fill={active? "#FDE8FF" : "#646464"}/>
        <path d="M19.0476 19.7468C14.686 19.1724 14.6737 13.4282 19.0476 12.8499V6.25761L15.188 3H5.05762V20.7337H19.0476V19.7468ZM12.8755 18.6134H6.29203C5.753 18.6057 5.74889 17.8539 6.29203 17.8424H12.8755C13.4146 17.8501 13.4187 18.6018 12.8755 18.6134ZM14.9329 15.9148H6.29203C6.06572 15.9148 5.88056 15.7413 5.88056 15.5293C5.88056 15.3172 6.06572 15.1437 6.29203 15.1437H14.9329C15.4719 15.1515 15.476 15.9032 14.9329 15.9148ZM14.9329 13.2162H6.29203C6.06572 13.2162 5.88056 13.0427 5.88056 12.8307C5.88056 12.6186 6.06572 12.4451 6.29203 12.4451H14.9329C15.4719 12.4528 15.476 13.2046 14.9329 13.2162ZM15.3444 10.132C15.3444 10.3441 15.1592 10.5176 14.9329 10.5176H9.17231C8.94601 10.5176 8.76084 10.3441 8.76084 10.132V9.74653C8.76084 8.61696 9.40685 7.60306 10.4232 7.06719C9.36982 5.86053 10.3903 3.92138 12.0526 3.96379C13.7149 3.92524 14.7354 5.86053 13.6779 7.06719C14.8506 7.66089 15.4513 8.90996 15.3444 10.132Z" fill={active? "#FDE8FF" : "#646464"}/>
        <path d="M11.3776 6.85901C12.6284 7.58764 13.9493 6.06485 12.9247 5.07022C11.5915 3.94066 9.91686 5.87595 11.3776 6.85901ZM21.4997 18.2086C23.5283 16.3003 21.5944 13.0465 18.7964 13.6749C17.5249 13.9371 16.5539 15.0821 16.5786 16.3003C16.5498 18.6712 19.7263 19.901 21.4997 18.2086ZM19.393 17.8077C19.2408 17.8732 19.0515 17.8424 18.9322 17.7306L17.8006 16.9403C17.3768 16.628 17.8541 16.0266 18.2944 16.3234L19.1832 16.948L20.9895 15.2555C21.3722 14.9009 21.9524 15.4406 21.5738 15.803L19.5165 17.7306C19.4794 17.7614 19.4383 17.7884 19.393 17.8077Z" fill={active? "#FDE8FF" : "#646464"}/>
    </svg>
  )
}

export default ApplicationIcon