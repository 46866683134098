import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {logo} from "constants";
import theme from 'theme';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from 'assets/icons/BackIcon';
import UserProfile from 'components/UserProfile';
import styles from 'assets/styles/header';

const Header = () => {
  const location = useLocation();
  const route = location.pathname.split('/')[1];
  console.log(route)

  const navigate = useNavigate()
  const name = "Reena S", role = "admin";


  return (
    <Box sx={styles.container}>
      <Box sx={styles.logoBox}>
        <img src={logo} alt='logo' />
      </Box>
      <Box sx={styles.linkBox}>
        {(route === "job" || route === "") &&
          <Button style={styles.link} onClick={() => {route === "job" ? navigate('/') : window.location.href = 'https://www.fanisko.com'}}>
            <Typography variant="body1" style={{color: theme.purple}}>
            {route ==="job" ? <Box sx={styles.backButton}><BackIcon/>Back</Box> : "Home"}
            </Typography>
          </Button>}
          {route ==="dashboard" && 
            <UserProfile name={name} role={role} styles={styles} />
          }
      </Box>
    </Box>
  );
}

export default Header;
