import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import id_ico from "assets/images/jobId.svg";
import department_ico from "assets/images/department.svg";
import calander_ico from "assets/images/date.svg";
import styles from 'assets/styles/jobDetailsTabs';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`job-details-tabpanel-${index}`}
      aria-labelledby={`job-details-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </Box>
  );
};

const JobDetailsTabs = ({ tabValue, handleTabChange, data }) => {

  const creation_date = data.created_on;
  const date = new Date(creation_date);
  const onlyDate = date.toDateString();


  return (
    <Box>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Job Details Tabs">
        <Tab label="Summary" sx={tabValue === 0 ? styles.activeTab : styles.tab} />
        <Tab label="Job Description" sx={tabValue === 1 ? styles.activeTab : styles.tab} />
        <Tab label="Benefits and Perks" sx={tabValue === 2 ? styles.activeTab : styles.tab} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Box component="ul" sx={styles.summaryList}>
          {Array.isArray(data.summary) ? 
            <>
            {data?.summary?.map((point) => (
              <li>{point}</li>
            ))} 
          </>
            :
            <>
              {data.summary}
              <Typography variant="body1" sx={styles.summaryTitle}>
                About Us
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: data?.about_us }} />
           </>
          }
        </Box>
        <Box sx={styles.additionalContainer}>
            <Box sx={styles.childContainer}>
              <Box style={styles.imageContainer}>
                <img src={id_ico} alt="JobId_ico" style={styles.image} />
              </Box>
              <Box>
              <Typography sx={styles.title}>Job ID</Typography>
              <Typography sx={styles.subTitle}>{data.job_id}</Typography>
              </Box>
            </Box>
            <Box sx={styles.childContainer}>
              <Box style={styles.imageContainer}>
                <img src={department_ico} alt="department_ico" style={styles.image} />
              </Box>
              <Box>
              <Typography sx={styles.title}>Department</Typography>
              <Typography sx={styles.subTitle}>{data.department_name}</Typography>
              </Box>
            </Box>
            <Box sx={styles.childContainer}>
              <Box style={styles.imageContainer}>
                <img src={calander_ico} alt="calander_ico" style={styles.image} />
              </Box>
              <Box>
              <Typography sx={styles.title}>Date Posted</Typography>
              <Typography sx={styles.subTitle}>{onlyDate}</Typography>
              </Box>
            </Box>
          </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box component="ul" sx={styles.summaryList}>
          {Array.isArray(data?.job_description) ? 
          <div>
            {data?.job_description.map((point) => (
              <li>{point}</li>
            ))} 
          </div>
            : <>
                <Typography variant="body1" sx={styles.summaryTitle}>
                  What will you do?
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: data?.job_description }} />
              <Typography variant="body1" sx={styles.summaryTitle}>
                  What should you already have?
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: data?.minimum_questions }} />
              </>}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Box component="ul" sx={styles.summaryList}>
          {Array.isArray(data?.benefits_and_perks) ?
            <>
              {data?.benefits_and_perks.map((point) => (
                <li>{point}</li>
              ))}
            </>
            : <>
              {JSON.parse(data?.benefits_and_perks).map((point) => (
                <li>{point}</li>
              ))}
            </>}
        </Box>
      </TabPanel>
      
    </Box>
  );
};

export default JobDetailsTabs;
