import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from 'assets/styles/applicationFormHeader';
import LockIcon from 'assets/icons/LockIcon';
import LocationFilledIcon from 'assets/icons/LocationFilledIcon';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';

const ApplicationFormHeader = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState()
  const { jobProfiles } = useAppContext();
  useEffect(()=> {
    const data = jobProfiles.find(profile => profile.job_id === id);
    setProfile(data)
  },[jobProfiles])
  return (
    <Box sx={styles.container}>
      <Typography variant="h1" sx={styles.title}>
        Job Application Form
      </Typography>
      <Box sx={styles.jobTitleContainer}>
        <LockIcon />
        <Box sx={styles.jobTitleText}>
          <Typography variant="h4" sx={styles.jobTitle}>
            {profile?.job_title}
          </Typography>
          <Typography variant="body1" sx={styles.jobLocation}>
            <LocationFilledIcon />
            {profile?.location}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationFormHeader;
