import theme from 'theme'
const styles = {
    dashboard: {
      display: "flex",
      gap: 88,
      width: "100%"
    },
    applicationsData: {
      paddingTop: "75px",
      minWidth: "calc(100% - 88px - 256px - 73px)",
      paddingBottom: "100px"
    },
    TableTop: {
      marginTop: 37,
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between"
    },
    totalApplicants: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal"
    },
    printButton: {
      borderRadius: "6px",
      border: `1px solid ${theme.purple}`,
      padding: 20,
      minWidth: 0,
      background: "#6D0185"
    },
    setStatus: {
      borderRadius: `25.347px`,
      border: `0.551px solid #BEA1FF`,
      minWidth: 105.47,
      maxWidth: 105.47,
      maxHeight: 37,
      color: "#5C5C5C",
      fontFamily: "Poppins",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      padding: 0,
      textAlign: "center"
    },
    tableContainer: {
      marginTop: 22,
      borderRadius: 20,
      background: "#FFF",
      boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.25)"
    },
    tableHead: {
      background: theme.lightPink,
      borderRadius: "20px 20px 0px 0px",
      borderRadius: 20,
      padding: "34px 18.5px"
    },
    tableCellStyle: {
        padding: "30px 0"
    },
    tableCellFont: {
      fontFamily: "Poppins",
      fontSize: "13px",
      fontWeight: 300,
      lineHeight: "19.5px",
      textAlign: "left"
    },
    tableLeftPadding: {
        paddingLeft: "19px"
    },
    filterPosting: {
      maxWidth: "273px"
    },
    pagination: {
      marginTop: "82px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    bulkTitle: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal"
    },
    bulkSubTitle: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "7.5px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal"
    },
    bulkButton: {
      borderRadius: "34.5px",
      background: "#E7DDFF",
      minWidth: 87,
      padding: "8px 12px",
      color: "#5C5C5C",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize"
    },
    bulkBox: {
      display: "flex",
      gap: 15,
      alignItems:"center",
      borderRadius: "16px",
      border: "1px solid #646464",
      background: "rgba(217, 217, 217, 0.00)",
      padding: "13px"
    },
    statusItem: {
      color: "#5C5C5C",
      fontFamily: "Poppins",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      padding: "6px"
    },
    filterIcon: {
      width: 16,
      height: 16,
      cursor: "pointer"
    },
    alignItems: {
      display: "flex",
      alignItems: "center",
      gap: 5
    },
    actionButtons: {
      minWidth: 0
    },
    listingButtonContainer: {
      display: "flex",
      alignItems: "center",
      gap: 29,
    },
    addJobListingBtn: {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal"
    },
    searchContainer: {
      borderRadius: '22px',
      border: '1px solid var(--Purple, #6D0185)',
      background: '#FFF',
      padding: '18px 25px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: 507,
      gap: '5px',
    },
    searchInput: {
      color: '#000',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: 'normal',
      border: 'none',
      outline: 'none',
      flexGrow: 1,
    },
    searchIcon: {
      color: '#6D0185',
    },

  addModal: {
    outline: "none",
    borderRadius: "33px",
    background: "#FFF",
    padding: "25px 25px 80px 0",
    width: "calc(100% - 32px)",
    maxWidth: "670px",
    position: "fixed",
    maxHeight: "90vh",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  modalHead: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    marginBottom: 20
  },
  modalTitle:{
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: theme.purple,
    marginBottom: 22
  },
  viewApplicationText: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: "28px",
    fontWeight: 500,
    color: theme.purple,
    marginBottom: 10
  },
  textDetails: {
    color: "#000"
  },
  viewApplicationDetails: {
    maxWidth: 524,
    position: "relative",
    margin: "0 auto"
  },
  modalBtnContainer: {
    display: "flex",
    gap: 10
  },
  modalButton: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: "28px",
    fontWeight: 400,
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "12px",
    border: `1px solid ${theme.purple}`,
    background: theme.purple,
    display: "flex",
    gap: 8.5,
  },
  filterBox: {
    display: "flex",
    gap: "17px",
    borderRadius: "10px",
    border: "0.75px solid #646464",
    background: "#FFF",
    padding: "14px 20px"
  },
  filterText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: "normal",
    fontWeight: 600,
    color: "#17181A"
  }
  }

export default styles