import React from 'react'

const CrossCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <g clip-path="url(#clip0_1830_673)">
    <path d="M17.0159 10.5159L14.5318 13L17.0159 15.4841C17.4395 15.9077 17.4395 16.5923 17.0159 17.0159C16.8047 17.2272 16.5273 17.3333 16.25 17.3333C15.9727 17.3333 15.6953 17.2272 15.4841 17.0159L13 14.5318L10.5159 17.0159C10.3047 17.2272 10.0273 17.3333 9.75 17.3333C9.47267 17.3333 9.19533 17.2272 8.98408 17.0159C8.5605 16.5923 8.5605 15.9077 8.98408 15.4841L11.4682 13L8.98408 10.5159C8.5605 10.0923 8.5605 9.40767 8.98408 8.98408C9.40767 8.5605 10.0923 8.5605 10.5159 8.98408L13 11.4682L15.4841 8.98408C15.9077 8.5605 16.5923 8.5605 17.0159 8.98408C17.4395 9.40767 17.4395 10.0923 17.0159 10.5159ZM26 13C26 20.1684 20.1684 26 13 26C5.83158 26 0 20.1684 0 13C0 5.83158 5.83158 0 13 0C20.1684 0 26 5.83158 26 13ZM23.8333 13C23.8333 7.0265 18.9735 2.16667 13 2.16667C7.0265 2.16667 2.16667 7.0265 2.16667 13C2.16667 18.9735 7.0265 23.8333 13 23.8333C18.9735 23.8333 23.8333 18.9735 23.8333 13Z" fill="#D40000"/>
  </g>
  <defs>
    <clipPath id="clip0_1830_673">
      <rect width="26" height="26" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default CrossCircleIcon