import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Button, Modal, CircularProgress } from '@mui/material';
import axios from 'axios';
import LockIcon from 'assets/icons/LockIcon';
import ForwardIcon from 'assets/icons/ForwardIcon';
import styles from 'assets/styles/recentApplications';
import CrossIcon from 'assets/icons/CrossIcon';
import EyeIcon from 'assets/icons/EyeIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { useAppContext } from 'context/AppContext';
import validateToken from 'services/validateToken';
import { useNavigate } from 'react-router-dom';

const RecentApplications = () => {
  const [showModal, setShowModal] = useState(false);
  const [viewApplication, setViewApplication] = useState({});
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const { jobProfiles } = useAppContext();
  useEffect(() => {
    const fetchRecentApplications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard/recent-applications`);
        setApplications(response.data);
      } catch (error) {
        const isValidToken = await validateToken();
        if (!isValidToken) {
          navigate('/login');
          return;
        }
        else {
          fetchRecentApplications();
        }
        console.error('Error fetching recent applications:', error);
      }
    };

    fetchRecentApplications();
  }, []);

  const handleModal = (application) => {
    setShowModal(true);
    setViewApplication(application);
  };

  function convertDaysToMonthsAndDays(days) {
    const months = Math.floor(days / 30); 
    const remainingDays = days % 30;

    let result = "";
    if (months > 0) {
        result += `${months} month${months > 1 ? 's' : ''}`;
    }
    if (remainingDays > 0) {
        result += `${months > 0 ? ' and ' : ''}${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
    }

    return result;
  }
  
  const openPortfolioLink = (url) => {
  const validUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
  window.open(validUrl, '_blank');
  };
  
  const GetJobTitle = (id) => {
    const data = jobProfiles.find(profile => profile.job_id === id)
    return data.job_title
  }

const downloadAndOpenPdf = async (url) => {
  setIsDownloading(true);
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    const fileUrl = URL.createObjectURL(blob);

    if (contentType === 'application/pdf') {
      window.open(fileUrl, '_blank'); 
    } else if (contentType.includes('image')) {
      const imgWindow = window.open();
      imgWindow.document.write(`<img src='${fileUrl}' />`); 
    } else if (
      contentType === 'application/msword' || 
      contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'download.doc'; 

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1]; 
        }
      }

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName); 
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.error('Unsupported file type');
    }
  } catch (error) {
    console.error('Error downloading the file', error);
  }
  setIsDownloading(false);
};  

  return (
    <Box style={styles.box}>
      <Typography style={styles.title}>Most Recent Applications</Typography>
      <List style={styles.list}>
        {applications.map((app, index) => (
          <ListItem key={index} button style={styles.listItem}>
            <LockIcon width={30} height={23} />
            <Box>
              <Box>
                <Typography style={styles.titleText}>{app.job_title}</Typography>
              </Box>
              <Box>
                <Typography style={styles.ApplicantName}>{app.name}</Typography>
              </Box>
              <Box style={styles.details}>
                <Box style={styles.portfolio}>
                  <Typography style={styles.applicantDetailsText}>Experience</Typography>{`${app.experience.toFixed(1)} ${app.experience> 1 ? "Years" : "Year"}`}
                </Box>
                <Box style={styles.portfolio}>
                  <Typography style={styles.applicantDetailsText}>Notice</Typography>{app.notice_period} {app.notice_period> 1 ? "Days":"Day"}
                </Box>
                <Box style={styles.resume}>
                <Typography 
                  style={styles.applicantDetailsText} 
                >
                  Profile/Portfolio
                </Typography>
                  <Button style={{ minWidth: 0 }} onClick={() => openPortfolioLink(app.portfolio_link)}>
                    <ForwardIcon />
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box style={styles.buttonContainer}>
              <Button style={styles.applicationBtn} onClick={() => { downloadAndOpenPdf(`${process.env.REACT_APP_BASE_URL}/api/dashboard/resume/${app.applicant_id}`)}}>
                {'View Resume'}
              </Button>
              <Button style={styles.applicationBtn} onClick={() => handleModal(app)}>
                View Application
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box style={styles.addModal}>
          <Box style={styles.modalHead}>
            <Box style={{ cursor: "pointer" }} onClick={() => setShowModal(false)}>
              <CrossIcon width={15} height={15} />
            </Box>
          </Box>
          <Box style={styles.viewApplicationDetails}>
            <Typography style={styles.modalTitle}>
              Application Details for {viewApplication?.job_id ?  GetJobTitle(viewApplication?.job_id) : 'Not Available'}
            </Typography>
            <Box>
              <Typography style={styles.viewApplicationText}>Name<span style={styles.textDetails}>{viewApplication?.name || 'Not Available'}</span></Typography>
              <Typography style={styles.viewApplicationText}>Current Designation<span style={styles.textDetails}>{viewApplication?.current_designation || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Salary Expectation<span style={styles.textDetails}>{viewApplication?.expected_salary || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Contact Number<span style={styles.textDetails}>{viewApplication?.contact_number || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Email<span style={styles.textDetails}>{viewApplication?.email || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Link to portfolio/LinkedIn<span style={styles.textDetails}>{viewApplication?.portfolio_link || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Summary/Cover Letter<span style={styles.textDetails}>{viewApplication?.summary || "Not Available"}</span></Typography>
              <Typography style={styles.viewApplicationText}>Resume</Typography>
              <Box style={styles.modalBtnContainer}>
                <Button style={styles.modalButton} onClick={() => downloadAndOpenPdf(`${process.env.REACT_APP_BASE_URL}/api/dashboard/resume/${viewApplication.applicant_id}`)} >{isDownloading ? <CircularProgress size={24} /> : 'View'}<EyeIcon color="#fff" /></Button>
                <Button style={styles.modalButton} onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/api/dashboard/resume/${viewApplication.applicant_id}`, '_blank')}>Download<DownloadIcon /></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RecentApplications;
