import { Box, Button, Typography } from '@mui/material';
import CrossCircleIcon from 'assets/icons/CrossCircleIcon';
import styles from 'assets/styles/jobSubmitModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LocationModal = ({visible}) => {
  const navigate = useNavigate();
  const handleDismiss = () => {
    navigate(-1)
  };

  return (
    <Box sx={{...styles.overlayContainer,display: visible ? "unset": "none",}}>
      <Box sx={styles.container}>
        <CrossCircleIcon />
        <Typography style={{...styles.thankyouText, color: "#D40000"}}>Unable to Apply - Location Issue</Typography>
        <Typography style={{ ...styles.para, marginTop: "30px", marginBottom: "36px", fontWeight: "275", maxWidth: 410 }}>Your location doesn't meet our needs. Please review the job posting and reapply if eligible.</Typography>
        <Button style={{...styles.dismissBtn, marginTop: 0}} onClick={handleDismiss}>Okay</Button>
      </Box>
    </Box>
  );
};

export default LocationModal;
