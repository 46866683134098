import theme from "theme";

const styles = {
  filterBoxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8.4px"
  },
  filterTitle: {
          color: '#000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23.4px',
  },
    filterBox: {
      borderRadius: '8px',
      border: `1px solid ${theme.backGreyFilter}`,
      background: '#FFF',
      padding: '20px 11px 13px',
      minWidth: '152px',
    },
    label: {
      color: '#5C5C5C',
      fontFamily: 'Poppins',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    radioLabel: {
      color: '#5C5C5C',
      fontFamily: 'Poppins',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  numberInput: {
      display: "inline-block",
      minWidth: "27px",
      height: '24px',
      fill: '#FFF',
      strokeWidth: '0.3px',
      fontSize: 12,
      stroke: '#646',
      opacity: '0.6',
    },
    buttonBox: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    inputBox: {
      display: 'flex',
      alignItems: 'center',
    },
    experienceBox: {
      display: 'flex',
      gap: 9,
      marginBottom: "20px"
    },
    radioGroup: {
      display: 'flex',
      gap: 6,
      marginBottom: 10
    },
  };

  export default styles;