import React from 'react'

const LocationFilledIcon = ({width = 25, height = 25}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
  <path d="M12.8 2.25C8.6748 2.25 5.29999 5.55736 5.29999 9.60005C5.29999 15.1125 12.8 23.25 12.8 23.25C12.8 23.25 20.3 15.1125 20.3 9.60005C20.3 5.55736 16.9252 2.25 12.8 2.25ZM12.8 12.225C11.3002 12.225 10.1214 11.0698 10.1214 9.60005C10.1214 8.13019 11.3001 6.97505 12.8 6.97505C14.2998 6.97505 15.4786 8.13019 15.4786 9.60005C15.4786 11.0698 14.2998 12.225 12.8 12.225Z" fill="#6D0185"/>
</svg>
  )
}

export default LocationFilledIcon